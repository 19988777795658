import { useQuery, PATH, Call } from '../../services/NetworkManager';

export function getUserPaymentDetails({ key }) {
  return Call({
    method: 'get',
    path: PATH.createUserPaymentLink.replace('{{key}}', key),
  });
}

export function getUserPaymentWalletDetails({ key }) {
  return Call({
    method: 'get',
    path: PATH.createUserPaymentLinkWallet.replace('{{key}}', key),
  });
}

export function getCreateMetalLink({ key }) {
  return Call({
    method: 'get',
    path: PATH.createUserMetalPaymentLink.replace('{{key}}', key),
  });
}

export function useUserPaymentDetails(key?: string) {
  const { data, isLoading, error } = useQuery(
    ['userPaymentDetails', key],
    () => getUserPaymentDetails({ key }),
    {
      enabled: !!key,
    },
  );
  return { data, isLoading, error };
}

export function useUserPaymentWalletDetails(key?: string) {
  const { data, isLoading, error } = useQuery(
    ['userPaymentWalletDetails', key],
    () => getUserPaymentWalletDetails({ key }),
    {
      enabled: !!key,
    },
  );
  return { data, isLoading, error };
}

export function useCreateMetalLink(key?: string) {
  const { data, isLoading, error, refetch } = useQuery(
    ['userPaymentMetalLink', key],
    () => getCreateMetalLink({ key }),
    {
      enabled: !!key,
    },
  );
  return { data, isLoading, error, refetch };
}

export function postCreateMetalPayment({
  key,
  phone,
  successPath,
  activePath,
  failedPath,
  blockId,
  price,
  quantity,
}: {
  key: string;
  phone: string;
  successPath: string;
  activePath: string;
  failedPath: string;
  blockId: string;
  price: number;
  quantity: number;
}) {
  return Call({
    method: 'post',
    path: PATH.CreateMetalUserPayments,
    data: {
      key,
      phone,
      successPath,
      activePath,
      failedPath,
      blockId,
      price,
      quantity,
    },
  });
}

export function postCreateP2PPayment({ key, phone }: { key: string; phone: string }) {
  return Call({
    method: 'post',
    path: PATH.CreateP2PUserPayments,
    data: {
      key,
      phone,
    },
  });
}

export function useCreateP2PPayment(key?: string, phone?: string) {
  const { data, isLoading, error } = useQuery(['createPayment', key], () =>
    postCreateP2PPayment({ key, phone }),
  );
  return { data, isLoading, error };
}

export function postCreateWalletPayment({ key, phone }: { key: string; phone: string }) {
  return Call({
    method: 'post',
    path: PATH.CreateWalletUserPayments,
    data: {
      key,
      phone,
    },
  });
}

export function useCreateWalletPayment(key?: string, phone?: string) {
  const { data, isLoading, error } = useQuery(['createPayment', key], () =>
    postCreateWalletPayment({ key, phone }),
  );
  return { data, isLoading, error };
}
