import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const NameAndPAN = ({
  name = '-',
  pan = '-',
  memberLevel = 'SECONDARY_MEMBER',
  type,
  familyName,
}) => {
  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} gap={0.25}>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: '500',
          }}
        >
          {!name ? '-' : name?.length > 20 ? `${name.slice(0, 20)}...` : name}
        </Typography>
        {type === 'family'
          ? memberLevel === 'PRIMARY_MEMBER' && (
              <Box
                className="uppercase !w-fit mt-1"
                color={'green'}
                display={'flex'}
                alignItems={'left'}
                minHeight={25}
                padding={0.25}
                borderRadius={50}
                sx={{ background: '#1665341F' }}
                justifyContent={'flex-start'}
              >
                <Box>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    mr={1}
                    mt={0.5}
                    ml={0.5}
                    sx={{
                      fontSize: '10px',
                      fontWeight: '500',
                    }}
                  >
                    Primary Account
                  </Typography>
                </Box>
              </Box>
            )
          : type === 'client' &&
            familyName && (
              <Box
                className="uppercase !w-fit mt-1"
                color={'green'}
                display={'flex'}
                alignItems={'left'}
                minHeight={25}
                padding={0.25}
                borderRadius={50}
                sx={{ background: '#1665341F' }}
                justifyContent={'flex-start'}
              >
                <Box>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    mr={1}
                    mt={0.5}
                    ml={0.5}
                    sx={{
                      fontSize: '10px',
                      fontWeight: '500',
                    }}
                  >
                    {familyName}
                  </Typography>
                </Box>
              </Box>
            )}
      </Box>
      <Box display={'flex'} alignItems={'left'} justifyContent={'flex-start'}>
        <Typography
          variant="caption"
          display="block"
          gutterBottom
          sx={{
            fontSize: '12px',
            fontWeight: '400',
          }}
        >
          {pan}
        </Typography>
      </Box>
    </Box>
  );
};

export default NameAndPAN;
