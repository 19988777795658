import React from 'react';
import GoldFaqs from '../../../pages/DealDetail/DigitalGold/GoldFaqs';
import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

interface FaqProps {}

const Faq: React.FC<FaqProps> = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="pb-24 pt-3 max-w-screen-2xl mx-auto px-4 md:px-12">
        <button
          onClick={() => navigate(-1)}
          className="text-green-700 hover:underline mb-4 bg-green-700/10 rounded-full p-1"
        >
          <KeyboardBackspaceIcon />
        </button>
        <div className="pb-4 pt-2">
          <h4 className="text-2xl font-semibold">FAQs</h4>
          <p className="text-base text-slate-600">Frequently Asked Questions on Tap Invest</p>
        </div>
        <GoldFaqs />
      </div>
    </>
  );
};

Faq.displayName = 'Faq';

export default Faq;
