import { useLayoutEffect } from 'react';

export function useTokenMessageHandler(navigate, onTokenSet) {
  const allowedOrigins = [
    'https://partnerqa.fundsindia.com/partners/ifa/dashboard',
    'https://partnerqa.fundsindia.com/partners/ifa/dashboard/',
    'https://leafround-post-message.vercel.app',
    'https://partnerqa.fundsindia.com/',
    'https://partnerqa.fundsindia.com',
  ];

  useLayoutEffect(() => {
    const handleTokenMessage = (event) => {
      if (!localStorage.getItem('token')) {
        if (allowedOrigins.includes(event.origin)) {
          if (event.data?.type === 'CHECK_READY') {
            event.source.postMessage({ type: 'READY' }, event.origin);
          } else if (event.data?.type === 'AUTH_TOKEN' && event.data?.token) {
            const { token } = event.data;
            localStorage.setItem('token', token);
            onTokenSet(token); // Trigger validation when token is set
          }
        }
      }
    };

    window.addEventListener('message', handleTokenMessage);

    return () => {
      window.removeEventListener('message', handleTokenMessage);
    };
  }, [navigate, onTokenSet]);
}
