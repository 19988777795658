import React, { useMemo, useState } from 'react';
import {
  downloadPartnerInvestmentsCSV,
  useInvestments,
  useInvestmentsSummary,
} from './useInvestments';
import InvestmentTable from '../../features/investments/InvestmentTable';
import { MONTHS } from './constants';
import { numDifferentiation } from '../../utils/numbers';
import { useDelay } from '../../hooks/useDelay';
import TableSkeleton from '../../components/TableSkeleton';
import Input from '../../components/Fields/Input';
import { SearchOutlined } from '@mui/icons-material';
import { debounce } from '../../utils/debounce';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import { NetworkManager } from '../../services';
import { useDownloadCsv } from '../../hooks/useDownloadCsv';
import ReinvestModal from '../../components/organisms/ReinvestModal/ReinvestModal';
import { Tab, Tabs } from '@mui/material';
import { useProfile } from '../../context/ProfileContext';

interface InvestmentsProps {}

const Investments: React.FC<InvestmentsProps> = () => {
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(0);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [search, setSearch] = useState('');
  const [tab, setTab] = useState(0);

  const { profileData } = useProfile();

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleChangeSort = (e, columnId) => {
    if (columnId == orderBy) {
      setOrder(order == 'desc' ? 'asc' : 'desc');
    }
    setOrderBy(columnId);
  };

  interface InvestmentValueProps {
    tab: number;
    profileData: {
      productTypes: {
        enableInvoiceDiscounting?: boolean;
        enableBond?: boolean;
        enableTapReserve?: boolean;
        enableGold?: boolean;
      };
    };
  }

  const investmentValue = ({ tab, profileData }: InvestmentValueProps): string | undefined => {
    return tab === 0 && profileData?.productTypes?.enableInvoiceDiscounting
      ? 'INVOICE_DISCOUNTING'
      : tab === 1 && profileData?.productTypes?.enableBond
      ? 'BOND'
      : tab === 2 && profileData?.productTypes?.enableTapReserve
      ? 'TAP_RESERVE'
      : profileData?.productTypes?.enableGold && 'DIGITAL_GOLD';
  };

  const { data, isLoading } = useInvestments({
    payload: {
      pageNo,
      pageSize,
      orderBy,
      order,
      search,
      filters: [
        {
          field: 'productType',
          operation: 'EQUALS',
          value: investmentValue({ tab, profileData }),
        },
      ],
    },
  });

  // const { csvLoading, downloadCsv } = useDownloadCsv({
  //   path: NetworkManager.PATH.csvPartnerInvestment,
  //   filename: 'investment.csv',
  // });
  const [csvLoading, setCsvLoading] = useState(false);
  const downloadCsv = async () => {
    setCsvLoading(true);

    try {
      const response = await downloadPartnerInvestmentsCSV({
        pageNo,
        pageSize,
        orderBy,
        order,
        search,
        filters: [
          {
            field: 'productType',
            operation: 'EQUALS',
            value:
              tab === 0
                ? 'INVOICE_DISCOUNTING'
                : tab === 1
                ? 'BOND'
                : tab === 2
                ? 'TAP_RESERVE'
                : 'DIGITAL_GOLD',
          },
        ],
      });
      window.open(response?.data, '_blank');
    } catch (error) {
      console.error('Error downloading CSV:', error);
    } finally {
      setCsvLoading(false);
    }
  };

  const isInvestmentsReady = useDelay(isLoading, 500);

  const { data: summaryData, isLoading: isSummaryLoading } = useInvestmentsSummary();

  const { totalEarned, totalInvested, currentMonthEarnedIncome, month } = summaryData?.data || {};

  const currentMonth = useMemo(() => MONTHS[month - 1], [month]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const debounceHandleSearch = debounce(handleSearch, 500);
  const handleTabChange = (e, newValue) => {
    setTab(newValue);
  };

  return (
    <div className="my-6 flex flex-col max-w-7xl mx-auto">
      <div className="grid sm:grid-cols-2 md:grid-cols-3  border border-stone-300 rounded-lg bg-black/[.02]">
        <div className="p-4 md:p-6 border-b border-b-stone-300 md:border-b-0 md:border-r md:border-r-stone-300 ">
          <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">Total Invested</h3>
          <p className="text-2xl font-medium text-stone-700">
            {numDifferentiation(totalInvested || 0)}
          </p>
        </div>
        <div className="p-4 md:p-6 border-b border-b-stone-300 md:border-b-0 md:border-r md:border-r-stone-300 sm:border-l sm:border-l-stone-300 md:border-l-0">
          <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">
            Earnings till date
          </h3>
          <p className="text-2xl font-medium text-stone-700">
            {numDifferentiation(totalEarned || 0)}
          </p>
        </div>
        <div className="p-4 md:p-6 xl:border-r-0 border-b-stone-300 md:border-r-stone-300 md:border-r-0 ">
          <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">
            {currentMonth} Earnings
          </h3>
          <p className="text-2xl font-medium text-stone-700">
            {numDifferentiation(currentMonthEarnedIncome || 0)}
          </p>
        </div>
      </div>
      <Tabs
        className="mt-8 max-w-7xl"
        classes={{
          // indicator: tab === 2 ? '!bg-amber-700' : '!bg-green-700',
          indicator: '!bg-green-700',
          flexContainer: 'border-b border-b-black/[0.08]',
        }}
        value={tab}
        onChange={handleTabChange}
        aria-label="deals-tabs"
      >
        {profileData?.productTypes?.enableInvoiceDiscounting && (
          <Tab
            label="Invoice Discounting"
            id="invoice-discounting"
            className="!capitalize !font-medium"
            classes={{
              selected: '!text-green-700',
            }}
            aria-controls="deals-tabpanel-0"
          />
        )}
        {profileData?.productTypes?.enableBond && (
          <Tab
            label="Bonds"
            id="bonds"
            className="!capitalize !font-medium"
            classes={{
              selected: '!text-green-700',
            }}
            aria-controls="deals-tabpanel-1"
          />
        )}
        {profileData?.productTypes?.enableTapReserve && (
          <Tab
            label="Tap Reserve"
            id="p2p"
            className="!capitalize !font-medium"
            classes={{
              selected: '!text-green-700',
            }}
            aria-controls="deals-tabpanel-1"
          />
        )}
        {/* <Tab
          label="Wallet Transactions"
          className="!capitalize !font-medium"
          classes={{
            selected: '!text-green-700',
          }}
          id="wallet"
          aria-controls="deals-tabpanel-2"
        /> */}
      </Tabs>
      <div className="flex flex-col justify-start gap-3 items-start md:flex-row md:justify-between md:items-center mt-6 mb-4">
        <Input
          prefixIcon={<SearchOutlined />}
          placeholder="Search"
          onChange={debounceHandleSearch}
          className="ps-11"
        />
        <div className="flex gap-5">
          {/* <button
            // onClick={downloadCsv}
            type="button"
            className="bg-white shadow border border-slate-300 flex items-center gap-2 text-sm  text-gray-800 rounded-lg font-semibold h-10 w-fit px-4"
          >
            Filters
            <FilterListIcon fontSize="small" />
          </button> */}
          <button
            onClick={downloadCsv}
            disabled={data?.data?.content.length < 1 || csvLoading}
            type="button"
            className={`${
              data?.data?.content.length < 1 || csvLoading
                ? 'bg-green-800/60 cursor-notallowed'
                : 'bg-green-800 shadow border'
            }border-green-800 flex items-center gap-2 text-sm  text-white rounded-lg font-semibold h-10 w-fit px-4`}
          >
            {csvLoading ? 'Downloading...' : ' Download Excel'}
            <DownloadForOfflineOutlinedIcon fontSize="small" />
          </button>
        </div>
      </div>

      {/* Investments Table */}
      {isInvestmentsReady && !isLoading ? (
        <InvestmentTable
          data={data}
          pageSize={pageSize}
          pageNo={pageNo}
          orderBy={orderBy}
          order={order}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangeSort={handleChangeSort}
          tab={
            tab === 0
              ? 'INVOICE_DISCOUNTING'
              : tab === 1
              ? 'BOND'
              : // : tab === 2
                // ? 'DIGITAL_GOLD'
                'TAP_RESERVE'
          }
        />
      ) : (
        <TableSkeleton />
      )}
      <ReinvestModal />
    </div>
  );
};

Investments.displayName = 'Investments';

export default Investments;
