import React, { useState } from 'react';
import Input from '../../components/Fields/Input';
import { Field } from '../../components/Fields/Field';
import OTPWrapper from './OTPWrapper';
import * as yup from 'yup';
import 'yup-phone-lite';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NetworkManager } from '../../services';
import { useOnboardingNavigate } from './store';
import { toast } from 'react-hot-toast';
import { kycError } from '../../utils/toast';
import KYCButton from './KYCButton';
import { ONBOARDING_EVENTS } from './constants';
import { useAnalytics } from '../../hooks/useAnalytics';
import { useSearchParams } from 'react-router-dom';

interface PhoneAuthProps {}

const incorrectOtp = () =>
  toast.error('Invalid OTP', {
    duration: 2500,
    position: 'top-center',
  });

const PhoneAuth: React.FC<PhoneAuthProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { handleNextPage } = useOnboardingNavigate();
  const [isVerifyStep, setVerifyStep] = useState(false);
  const [isOtpResend, setIsOtpResend] = useState(false);
  const [timeForOtpResend, setTimeForOtpResend] = useState(30);
  const [phone, setPhone] = useState<number | null>(null);
  const [searchParams] = useSearchParams();

  const { track } = useAnalytics();

  const schema = yup.object().shape({
    otp: yup.number().when('phoneSaved', ([phoneSaved]) => {
      if (phoneSaved) {
        return yup
          .number()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .max(9999, 'Must be a 4-digit OTP')
          .min(1000, 'Must be a 4-digit OTP')
          .required('Please enter OTP');
      } else {
        return yup
          .number()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .nullable();
      }
    }),
    phoneSaved: yup.bool().default(false),
    phone: yup
      .string()
      .phone('IN', 'Please enter a valid phone number')
      .required('Phone Number is required')
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { phone: null },
  });

  const submitPhone = (phone: number) => {
    return NetworkManager.Call({
      method: 'post',
      path: NetworkManager.PATH.phoneOTP,
      data: { phone },
    });
  };

  const submitOTP = (phone: number, otp: number) => {
    const referralCodeValue = searchParams.get('refCode');
    const data = {
      phone,
      otp,
      ...(referralCodeValue &&
        referralCodeValue !== '' && {
          referralCode: referralCodeValue,
        }),
    };

    return NetworkManager.Call({
      method: 'post',
      path: NetworkManager.PATH.verifyPhoneOTP,
      data,
      noHeaders: true,
      disableRedirect: true,
    });
  };

  const onSubmit = async (data: Record<string, any>) => {
    const { phone, otp } = data;
    setIsLoading(true);
    setTimeForOtpResend(30);
    setPhone(phone);

    const handleError = (message: string) => {
      toast.error(message, {
        duration: 2500,
        position: 'top-center',
      });
      setIsLoading(false);
    };

    try {
      if (isVerifyStep) {
        const otpRes = await submitOTP(phone, otp);

        if (otpRes?.code?.toString().startsWith('2')) {
          handleNextPage(otpRes.nextPage, otpRes.message);
        } else if (otpRes.error.includes('Invalid OTP')) {
          incorrectOtp();
        } else {
          kycError('Something went wrong! Please try again');
        }
      } else {
        const phoneRes = await submitPhone(phone);

        if (!phoneRes?.error) {
          setValue('phoneSaved', true);
          setValue('otp', null);
          setVerifyStep(true);
          handleEntryEvent(ONBOARDING_EVENTS.Phone_OTP_Verify);
        } else {
          throw new Error(phoneRes.error);
        }
      }
    } catch (error: any) {
      if (error?.response?.status?.toString().startsWith('5')) {
        handleError('Something went wrong! Please try again');
      } else if (error?.response?.status?.toString().startsWith('4')) {
        handleError(error?.response?.data?.message);
      } else {
        handleError('Something went wrong! Please try again');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleEntryEvent = (event: string) => {
    track({ eventName: event });
  };

  React.useEffect(() => {
    if (timeForOtpResend > 0) {
      const timerId = setTimeout(() => {
        setTimeForOtpResend(timeForOtpResend - 1);
      }, 1000);
      return () => clearTimeout(timerId);
    } else {
      setIsOtpResend(true);
    }
  }, [timeForOtpResend]);

  const handleResend = async () => {
    setIsOtpResend(true);

    try {
      const otpRes = await submitPhone(phone);

      if (otpRes?.code?.toString().startsWith('2')) {
        toast.success('OTP sent successfully', {
          duration: 2500,
          position: 'top-center',
        });
      } else if (otpRes.error.includes('Invalid OTP')) {
        incorrectOtp();
      } else {
        kycError('Something went wrong! Please try again');
      }
    } catch (err) {
      if (err?.response?.status?.toString().startsWith('4')) {
        toast.error(
          `${err?.response?.data?.message || 'Something went wrong, please try again later'}`,
          {
            duration: 2500,
            position: 'top-center',
          },
        );
      } else {
        toast.error('Something went wrong! Please try again', {
          duration: 2500,
          position: 'top-center',
        });
      }
    } finally {
      setIsOtpResend(false);
      setTimeForOtpResend(30);
    }
  };

  return (
    <OTPWrapper
      title="Let’s get started"
      verifyTitle="Verifying OTP"
      subtitle="Please enter your mobile number."
      verifySubtitle="Please enter OTP sent on your mobile number"
      isVerifyStep={isVerifyStep}
      resetVerify={() => {
        setValue('phoneSaved', false);
        setValue('phone', null);
        setVerifyStep(false);
        setIsLoading(false);
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {isVerifyStep ? (
          <Field label="OTP" error={errors.otp?.message}>
            <Input type="text" numericOnly={true} placeholder="Enter OTP" {...register('otp')} />
          </Field>
        ) : (
          <Field label="Phone Number" error={errors.phone?.message}>
            <Input
              onClick={() => handleEntryEvent(ONBOARDING_EVENTS.Phone_Number_Initiation)}
              type="text"
              numericOnly={true}
              placeholder="9321xxxxxx"
              {...register('phone')}
              prefixNode={<span className="text-slate-600 font-semibold text-sm">+91</span>}
            />
          </Field>
        )}
        <KYCButton
          isLoading={isLoading}
          text={isVerifyStep ? 'Confirm & Proceed' : 'Send Verification Code'}
        />
        {isVerifyStep && (
          <button
            disabled={!isOtpResend}
            type="button"
            onClick={handleResend}
            className={`${
              !isOtpResend ? 'mx-[27%]' : 'mx-[32%]'
            } text-sm px-7 rounded-xl text-black/80  h-10 mt-5 `}
          >
            Resend OTP {!isOtpResend ? `In ${timeForOtpResend}` : ''}
          </button>
        )}
      </form>
    </OTPWrapper>
  );
};

PhoneAuth.displayName = 'PhoneAuth';

export default PhoneAuth;
