import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Divider,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import WalletIcon from '@mui/icons-material/Wallet';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import dayjs from 'dayjs';
import { getRupeeWithDecimal } from '../../../../utils/numbers';
import { downloadTransactionInvoice } from '../../useDealDetails';
import toast from 'react-hot-toast';

const TransactionDetailsCard = ({ open, onClose, transaction }) => {
  const { metal, action, quantity, date, amount, status, invoiceId } = transaction;
  const [downloadButtonLoading, setDownloadButtonLoading] = React.useState(false);
  const formatDate = (dateString) => {
    return dayjs(dateString).format('D MMMM YYYY, h:mm A');
  };

  // Function to generate PDF
  const generatePDF = () => {
    setDownloadButtonLoading(true);
    downloadTransactionInvoice(invoiceId)
      .then((resp) => {
        const documentUrl = resp?.data.signedURL;
        const link = document.createElement('a');
        link.href = documentUrl;
        link.download = 'Invoice' + invoiceId + '.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.success('Invoice downloaded successfully');
      })
      .catch((err) => {
        toast.error('Error downloading invoice');
      })
      .finally(() => {
        setDownloadButtonLoading(false);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth style={{ padding: '20px' }}>
      <DialogTitle>
        <div className="flex items-center justify-center flex-col gap-6 ">
          <div className="flex items-center justify-center mt-8 mr-10 relative">
            <div
              className="bg-green-700 text-white p-2 rounded-full flex items-center justify-center"
              style={{ width: '45px', height: '45px', zIndex: 10 }}
            >
              {status === 'SUCCESS' ? (
                <DoneRoundedIcon style={{ width: '15px', height: '15px' }} />
              ) : status === 'FAILED' ? (
                <ClearRoundedIcon style={{ width: '15px', height: '15px' }} />
              ) : (
                <PriorityHighRoundedIcon style={{ width: '15px', height: '15px' }} />
              )}
            </div>
            <div
              className="bg-white rounded-full flex items-center justify-center absolute"
              style={{
                width: '45px',
                height: '45px',
                left: '40px', // Adjusting for the overlap
                border: '2px solid rgba(0, 128, 0, 0.2)',
              }}
            >
              <WalletIcon
                className="bg-white p-2 rounded-full flex justify-center items-center"
                style={{ width: '35px', height: '35px' }}
              />
            </div>
          </div>
          <div className=" flex flex-col gap-1 items-center justify-center text-base">
            <div className="ml-3 text-[10px] bg-stone-200 font-semibold rounded-md w-fit px-2">
              DIGITAL {metal}
            </div>
            <div className="mt-2 font-semibold text-base">
              {action === 'sell' ? 'Withdraw' : 'Deposit'} {quantity} gm{' '}
              {status === 'SUCCESS' ? 'Successful' : status === 'PENDING' ? 'Pending' : 'Failed'}
            </div>
            <div className="text-sm font-normal">{formatDate(date)}</div>

            <div className="mt-2 text-2xl tracking-tight">{getRupeeWithDecimal.format(amount)}</div>
          </div>
        </div>
      </DialogTitle>
      <DialogContent className="border-b">
        <div className="flex justify-between">
          <p className="text-stone-500 text-sm">Status</p>
          <Typography
            color={status === 'SUCCESS' ? 'green' : status === 'PENDING' ? 'orange' : 'red'}
          >
            {status.toLowerCase()}
          </Typography>
        </div>

        <div className="flex justify-between my-2">
          <p className="text-stone-500 text-sm">GST</p>
          <Typography>3%</Typography>
        </div>

        <div className="flex justify-between my-2">
          <p className="text-stone-500 text-sm">Transaction ID</p>
          <Typography>{invoiceId || '-'}</Typography>
        </div>
      </DialogContent>
      <DialogActions className="flex flex-col">
        {/* <p className="text-[10px] py-[10px]">
          Have questions regarding the transaction?{' '}
          <span className="underline underline-offset-1">Contact Us</span>
        </p> */}
        <button
          className="flex items-center justify-center gap-1 px-4 py-2 text-white rounded-lg w-full mb-4"
          style={{
            borderRadius: '999px',
            border: '1px solid #15803D',
            background: `${
              downloadButtonLoading
                ? 'bg-stone-200'
                : 'linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.00) 119.05%), #166534'
            }`,
            boxShadow: '0px 1.1px 1.8px -0.3px rgba(0, 0, 0, 0.10)',
          }}
          onClick={generatePDF}
          disabled={downloadButtonLoading}
        >
          {downloadButtonLoading ? (
            'Downloading...'
          ) : (
            <>
              <DownloadIcon style={{ width: '20px', height: '20px', marginRight: 2 }} />
              Download Invoice
            </>
          )}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default TransactionDetailsCard;
