import { useQuery } from '../../services/NetworkManager';
import { NetworkManager } from '../../services';

export function getAllInvestments({ queryKey }) {
  const [_, pageNo, pageSize, orderBy, order, search, filters] = queryKey;
  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.partnerInvestments,
    data: {
      currentPage: pageNo,
      pageSize,
      sortBy: orderBy,
      sortDir: order.toUpperCase(),
      searchPhrase: search,
      filters,
    },
  });
}

export function getGoldInvestments({
  fromDate,
  toDate,
  pageNumber,
  pageSize,
  sortByUpdatedAtDesc,
  transactionTypes,
  TransactionState,
  clientId,
}) {
  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.walletInvestments.replace('{{clientId}}', clientId),
    data: {
      fromDate,
      toDate,
      pageNumber,
      pageSize,
      sortByUpdatedAtDesc,
      transactionTypes,
      TransactionState,
    },
  });
}

export function downloadGoldInvestmentsCSV({ pageNo, pageSize, orderBy, order, search, filters }) {
  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.downloadWalletInvestments,
    data: {
      currentPage: pageNo,
      pageSize,
      sortBy: orderBy,
      sortDir: order.toUpperCase(),
      searchPhrase: search,
      filters,
    },
  });
}

export function useGoldInvestments({
  clientId,
  pageNumber,
  pageSize,
  fromDate,
  toDate,
  sortByUpdatedAtDesc,
  transactionTypes,
  TransactionState,
  search,
}) {
  const { data, isLoading } = useQuery('goldInvestments', () =>
    getGoldInvestments({
      fromDate,
      toDate,
      pageNumber,
      pageSize,
      sortByUpdatedAtDesc,
      transactionTypes,
      TransactionState,
      clientId,
    }),
  );
  return { data, isLoading };
}

export function getInvestmentsSummary() {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.investmentsSummary,
  });
}

export function useInvestments({ payload }) {
  const { pageNo, pageSize, orderBy, order, search, filters } = payload;
  const { data, isLoading } = useQuery(
    ['investments', pageNo, pageSize, orderBy, order, search, filters],
    ({ queryKey }) => getAllInvestments({ queryKey }),
  );
  return { data, isLoading };
}

export function useInvestmentsSummary() {
  const { data, isLoading } = useQuery('investmentsSummary', getInvestmentsSummary);

  return { data, isLoading };
}

export function downloadPartnerInvestmentsCSV({
  pageNo,
  pageSize,
  orderBy,
  order,
  search,
  filters,
}) {
  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.downloadPartnerInvestments,
    data: {
      currentPage: pageNo,
      pageSize,
      sortBy: orderBy,
      sortDir: order.toUpperCase(),
      searchPhrase: search,
      filters,
    },
  });
}
