import { Dialog } from '@mui/material';
import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import { useLayoutStore } from '../Layout/store';
import { formatDate } from '../../../utils/date';
import { getRupee, twoDecimalNumber } from '../../../utils/numbers';
import { KYC_STATUS } from '../../../pages/ClientDetail/constants';
import { NetworkManager } from '../../../services';
import { kycError } from '../../../utils/toast';
import { useNavigate } from 'react-router-dom';

interface ReinvestModalProps {}

const getKycStatusTag = (kycStatus) => {
  if (kycStatus == KYC_STATUS.REPAID_ON_TIME) {
    return (
      <div className="bg-green-200 w-fit rounded-lg text-green-700 py-1 px-2 text-xs font-semibold">
        Completed
      </div>
    );
  }
  if (kycStatus == KYC_STATUS.SCHEDULED) {
    return (
      <div className="bg-gray-200 w-fit rounded-lg text-gray-700 py-1 px-2 text-xs font-semibold">
        Ongoing
      </div>
    );
  }
};

const ReinvestModal: React.FC<ReinvestModalProps> = () => {
  const [reinvest, setReinvest] = useState(true);
  const { isReinvestModalOpen, reinvestDeal, setIsReinvestModalOpen } = useLayoutStore(
    ({ isReinvestModalOpen, reinvestDeal, setIsReinvestModalOpen }) => ({
      isReinvestModalOpen,
      reinvestDeal,
      setIsReinvestModalOpen,
    }),
  );

  const navigate = useNavigate();

  const handleReinvestChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReinvest(event.target.checked);
  };

  const handleConfirm = async () => {
    if (reinvest) {
      setIsReinvestModalOpen(false);
    } else {
      try {
        const investmentResponse = await NetworkManager.Call({
          method: 'post',
          path: NetworkManager.PATH.reinvest
            .replace('{{investmentId}}', reinvestDeal?.investmentId)
            .replace('{{clientId}}', reinvestDeal?.clientPublicId),
        });
        setIsReinvestModalOpen(false);

        navigate(0);
      } catch (error) {
        const { message } = error?.response?.data;
        if (message) {
          kycError(message);
        } else {
          kycError('Something went wrong');
        }
        setIsReinvestModalOpen(false);
      }
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={isReinvestModalOpen}
      onClose={() => setIsReinvestModalOpen(false)}
    >
      <div>
        <div>
          <div className="p-6 border-b border-b-stone-200">
            <h2 className="text-xl font-semibold my-1.5">
              Reinvest for your client ‘{reinvestDeal?.clientName}’
            </h2>
            <h3 className="text-sm mb-3">{reinvestDeal?.companyName}</h3>
            <div className="flex items-center gap-8">
              <p className="text-stone-800 text-xs">
                <span className="text-gray-700 font-medium opacity-40">Pan Number</span>{' '}
                {reinvestDeal?.pan}
              </p>
              {/* <p className="text-stone-800 text-xs"><span className="text-gray-700 font-medium opacity-40">Phone Number</span> {reinvestDeal?.phone}</p> */}
            </div>
          </div>
          <div className="p-6">
            <div className="flex flex-wrap justify-between items-center gap-10 mt-5">
              <p className="text-stone-700 font-medium flex flex-col gap-1">
                <span className="uppercase text-stone-400 font-semibold text-xs">Payout Date</span>{' '}
                {formatDate(reinvestDeal?.maturityDate)}
              </p>
              <p className="text-stone-700 font-medium flex flex-col gap-1">
                <span className="uppercase text-stone-400 font-semibold text-xs">
                  Payout Amount
                </span>{' '}
                {getRupee.format(reinvestDeal?.payoutAmount)}
              </p>
              <p className="text-stone-700 font-medium flex flex-col gap-1">
                <span className="uppercase text-stone-400 font-semibold text-xs">Status</span>{' '}
                {getKycStatusTag(reinvestDeal?.status)}
              </p>
              {/* <p className="text-stone-700 font-medium flex flex-col gap-1"><span className="uppercase text-stone-400 font-semibold text-xs">Transaction Id</span> { }</p> */}
              <p className="text-stone-700 font-medium flex flex-col gap-1">
                <span className="uppercase text-stone-400 font-semibold text-xs">IRR</span>{' '}
                {reinvestDeal?.irr ? twoDecimalNumber(reinvestDeal.irr) + '%' : '-'}
              </p>
            </div>

            <div className="border border-stone-200 rounded-lg p-5 mt-6">
              <div className="flex justify-between items-center">
                <div className="flex gap-1 items-center">
                  <Tooltip
                    title="Automatically reinvest your payout in the same company. Your payout will be reinvested only if the XIRR is equal to or greater than the current investment and the tenure remains the same."
                    placement="top"
                    arrow
                  >
                    <span className="underline text-slate-600 text-sm">Re-invest</span>
                  </Tooltip>
                  <span className="text-[8px] text-green-700 uppercase font-semibold py-0.5 px-1 bg-green-700/10">
                    New
                  </span>
                </div>
                <div>
                  <Switch
                    checked={reinvest}
                    onChange={handleReinvestChange}
                    classes={{
                      track: reinvest && '!bg-green-600 !opacity-100',
                      switchBase: '!text-white',
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </div>
              </div>

              <ul className="list-disc flex flex-col gap-2 text-stone-500 text-sm ml-2 mt-4">
                <li>
                  You will be able to change your re-investment preference only before 2 days till
                  the initial payout date.
                </li>
                <li>
                  Your re-investment preference can only be updated once. That is, you will be
                  unable to opt in if you opt out once.
                </li>
                <li>
                  Your payout will be re-invested only under the condition that the new XIRR is
                  either equal or greater than the current investment XIRR and the tenure is same as
                  current investment.
                </li>
              </ul>
            </div>
          </div>
          <div className="flex w-full p-6 border-t border-t-stone-200 justify-end gap-2">
            <button
              className="bg-green-600 rounded-xl h-10 px-7 text-sm text-white font-semibold"
              type="button"
              onClick={handleConfirm}
            >
              Confirm Changes
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

ReinvestModal.displayName = 'ReinvestModal';

export default ReinvestModal;
