export const DEAL_EVENTS = {
  Bonds: 'Bonds',
  Gold: 'GOLD',
  Tap_Reserve_3M_plan: 'Tap_Reserve_3M_plan',
  Tap_Reserve_6M_plan: 'Tap_Reserve_6M_plan',
  Tap_Reserve_enter_amount: 'Tap_Reserve_enter_amount',
  Tap_reserve_Client_select: 'Tap_reserve_Client_select',
  Tap_Reserve_Complete_KYC: 'Tap_Reserve_Complete_KYC',
  Tap_Reserve_Generate_Payment_Link: 'Tap_Reserve_Generate_Payment_Link',
};
