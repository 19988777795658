import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useDealClients } from './useDealDetails';
import { getRupee } from '../../utils/numbers';
import clsx from 'clsx';
import { useAnalytics } from '../../hooks/useAnalytics';
import { Button, Checkbox, ListItemText } from '@mui/material';
import { CLIENTS_EVENTS } from '../Clients/constants';
import { useLayoutStore } from '../../components/organisms/Layout/store';

interface Client {
  clientPublicId: string;
  name: string;
  pan: string;
  balance: number;
  family: any;
  familyName?: string;
  isKycVerified?: boolean;
  goldInGms?: number;
}

interface SelectClientProps {
  client: Client | Client[]; // Can be a single client or an array
  setClient: (clients: Client | Client[]) => void;
  isTapReserve?: boolean;
  showGold?: boolean;
  eventName?: string;
  showAddNewClient?: boolean;
  setLoading?: (loading: boolean) => void;
  multiple?: boolean;
  value?: string;
  onChange?: () => void;
  refetchClients?: boolean;
}

const ObservedMenuItem: React.FC<{ onIntersect: () => void }> = ({ onIntersect }) => {
  const itemRef = useRef<HTMLLIElement | null>(null);

  useEffect(() => {
    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          onIntersect();
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, { threshold: 1.0 });

    if (itemRef.current) {
      observer.observe(itemRef.current);
    }

    return () => {
      if (itemRef.current) {
        observer.unobserve(itemRef.current);
      }
    };
  }, [onIntersect]);

  return <MenuItem ref={itemRef} disabled className="invisible" />;
};

const SelectClient: React.FC<SelectClientProps> = ({
  client,
  setClient,
  eventName,
  showAddNewClient = true,
  setLoading,
  multiple = false,
  value,
  onChange,
  showGold = false,
  refetchClients = false,
}) => {
  const [pageNo, setPageNo] = useState(0);
  const [selectClients, setSelectClients] = useState<Client[]>([]);

  const {
    data: dealClients,
    isLoading: dealClientsLoading,
    refetch,
  } = useDealClients(pageNo, value);

  const { items: clients = [], totalItems = 0 } = dealClients?.data || {};
  const { track } = useAnalytics();

  useEffect(() => {
    if (refetchClients) {
      refetch();
      setSelectClients([]);
    }
  }, [refetch, refetchClients]);

  useEffect(() => {
    setLoading?.(dealClientsLoading);
  }, [dealClientsLoading, setLoading]);

  const filteredClients = useMemo(() => {
    return multiple
      ? clients.filter((client) => !client.familyName)
      : clients.filter((client) => client?.isKycVerified);
  }, [clients, multiple]);

  const hasMoreClients = useMemo(() => {
    const basicCondition =
      totalItems > pageNo * 10 && totalItems > clients.length && filteredClients.length > 9;
    const additionalCondition = multiple
      ? selectClients.filter((client) => client?.isKycVerified).length > 9
      : true;

    return basicCondition && additionalCondition;
  }, [totalItems, pageNo, clients, filteredClients, selectClients, multiple]);

  const handleChange = useCallback(
    (e: SelectChangeEvent<string | string[]>) => {
      if (multiple) {
        const selectedClientIds = e.target.value as string[];
        const selectedClients = selectClients.filter((client) =>
          selectedClientIds.includes(client.clientPublicId),
        );
        setClient(selectedClients);
      } else {
        const selectedClientId = e.target.value as string;
        const selectedClient = selectClients.find(
          (client) => client.clientPublicId === selectedClientId,
        );
        setClient(selectedClient || ({} as Client));
      }
      track({ eventName });
      onChange?.();
    },
    [multiple, selectClients, setClient, track, eventName, onChange],
  );

  const clientElement = useCallback(
    ({ name, pan, balance, familyName, goldInGms }: Client) => (
      <div className="flex justify-between items-center w-full">
        <div className="flex gap-3">
          <div className="flex flex-col">
            <h4 className="text-sm font-medium text-slate-600">
              {name.length > 15 ? `${name.slice(0, 15)}...` : name}
            </h4>
            <h3 className="text-xs font-medium text-slate-600/60 mt-1">{pan}</h3>
          </div>
          {familyName && (
            <h3 className="text-xs font-medium text-green-800 h-fit bg-green-800/15 rounded-xl px-1 py-[6px]">
              {familyName.length > 12 ? `${familyName.slice(0, 12)}...` : familyName}
            </h3>
          )}
        </div>
        <p className="text-sm font-medium text-slate-600">
          {showGold ? goldInGms + '/gm' : getRupee.format(balance)}
        </p>
      </div>
    ),
    [],
  );

  useEffect(() => {
    if (!dealClientsLoading && filteredClients.length > 0) {
      setSelectClients((prev) => {
        const updatedClients = pageNo === 0 ? filteredClients : [...prev, ...filteredClients];

        // Prevent unnecessary updates if state is already the same
        if (prev.length !== updatedClients.length) {
          return updatedClients;
        }

        return prev;
      });
    }
  }, [pageNo, filteredClients, dealClientsLoading]);

  const handleClientsRequest = useCallback(() => {
    setPageNo((prev) => prev + 1);
  }, []);

  const { setIsClientDrawerOpen } = useLayoutStore();

  const handleAddNewClient = useCallback(() => {
    setIsClientDrawerOpen(null);
    track({ eventName: CLIENTS_EVENTS.Add_new_Client_From_ID });
  }, [setIsClientDrawerOpen, track]);

  const isClientSelected = useCallback(
    (clientId: string) =>
      multiple
        ? (client as Client[]).some((selectedClient) => selectedClient.clientPublicId === clientId)
        : (client as Client)?.clientPublicId === clientId,
    [client, multiple],
  );

  return (
    <>
      {dealClientsLoading ? (
        <MenuItem disabled value="" className="!px-0">
          <p className="text-stone-700 text-xs bg-white !rounded-lg border border-stone-800 w-full h-8 pl-4 flex items-center ">
            Fetching Clients...
          </p>
        </MenuItem>
      ) : filteredClients.length > 0 ? (
        <Select
          multiple={multiple}
          displayEmpty
          value={
            multiple
              ? (client as Client[]).map((c) => c.clientPublicId)
              : (client as Client)?.clientPublicId || ''
          }
          IconComponent={KeyboardArrowDown}
          className={clsx('w-full bg-white !rounded-lg', {
            'h-10': !client,
          })}
          onChange={handleChange}
          renderValue={(selected) => {
            if (!selected || (Array.isArray(selected) && selected.length === 0)) {
              return <p className="text-gray-400">Select Client</p>;
            }

            if (multiple) {
              return (
                <div className="flex flex-wrap gap-1">
                  {(selected as string[]).map((clientId) => {
                    const selectedClient = selectClients.find(
                      (client) => client.clientPublicId === clientId,
                    );
                    return selectedClient ? (
                      <div
                        key={selectedClient.clientPublicId}
                        className="bg-gray-100 p-1 rounded text-sm"
                      >
                        {selectedClient.name}
                      </div>
                    ) : null;
                  })}
                </div>
              );
            } else {
              const selectedClient = selectClients.find(
                (client) => client.clientPublicId === selected,
              );
              return selectedClient ? (
                clientElement(selectedClient)
              ) : (
                <p className="text-gray-400">Select Client</p>
              );
            }
          }}
          MenuProps={{
            MenuListProps: {
              className: 'max-h-80 overflow-auto',
            },
          }}
          input={<OutlinedInput />}
        >
          <MenuItem disabled value="" className="!px-0">
            <div className="flex px-4 justify-between w-full border-b border-b-gray-400 pb-2">
              <h4 className="text-stone-700 text-xs">Name of Client</h4>
              <p className="text-stone-700 text-xs"> {showGold ? 'Gold' : 'Wallet'} Balance</p>
            </div>
          </MenuItem>
          {filteredClients.map((client) => (
            <MenuItem key={client.clientPublicId} value={client.clientPublicId}>
              {multiple && (
                <Checkbox checked={isClientSelected(client.clientPublicId)} className="p-0 mr-2" />
              )}
              <ListItemText primary={clientElement(client)} />
            </MenuItem>
          ))}
          {hasMoreClients && !dealClientsLoading && (
            <ObservedMenuItem onIntersect={handleClientsRequest} />
          )}
        </Select>
      ) : showAddNewClient ? (
        <Button
          fullWidth
          onClick={handleAddNewClient}
          className="!text-sm !disabled:border-green-700/60 !mx-auto !text-green-700 !rounded-xl !font-bold !h-10 !w-full !capitalize !border-green-700"
          style={{ border: '1px solid' }}
        >
          Add new client
        </Button>
      ) : (
        <MenuItem disabled value="" className="!px-0">
          <div className="px-4 w-full border-b border-b-gray-400 pb-2">
            <p className="text-stone-700 text-xs">No Clients available for selection</p>
          </div>
        </MenuItem>
      )}
    </>
  );
};

SelectClient.displayName = 'SelectClient';

export default SelectClient;
