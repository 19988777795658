import React, { useState, useEffect } from 'react';

interface LoadingDotsProps {
  interval?: number; // Optional interval prop to control the speed
}

const LoadingDots: React.FC<LoadingDotsProps> = ({ interval = 500 }) => {
  const [dotCount, setDotCount] = useState(1);

  useEffect(() => {
    // Set up an interval to change the dot count
    const dotInterval = setInterval(() => {
      setDotCount((prev) => (prev % 3) + 1); // Cycle between 1, 2, and 3 dots
    }, interval);

    return () => {
      clearInterval(dotInterval); // Clean up the interval on unmount
    };
  }, [interval]);

  return <span>{'.'.repeat(dotCount)}</span>;
};

export default LoadingDots;
