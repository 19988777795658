import { useEffect } from 'react';
import { useTimer } from '../../../../utils/timer';
import { Typography } from '@mui/material';

export const TimerComponent = ({ expiresAt }) => {
  const givenDate = new Date(expiresAt);
  const now = new Date();
  const diffInMs = givenDate.getTime() - now.getTime();
  const diffInSeconds = Math.floor(diffInMs / 1000);
  const { timer, start } = useTimer(diffInSeconds);
  useEffect(() => {
    start();
  }, []);
  return (
    <Typography
      sx={{
        width: '35px',
        fontSize: 'inherit',
        color: 'inherit',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {`${Math.floor(timer / 60)}`?.length < 2 ? '0' : ''}
      {Math.floor(timer / 60)}:{`${timer % 60}`?.length < 2 ? '0' : ''}
      {timer % 60}
    </Typography>
  );
};
