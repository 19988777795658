import React, { useState } from 'react';
import TransactionDetailsCard from './TransactionDetailsCard';
import Calender from '../../../../components/molecules/Calender';
import { getRupeeWithDecimal } from '../../../../utils/numbers';
import toast from 'react-hot-toast';

export const TransactionTile = ({ transaction }) => {
  const [open, setOpen] = useState(false);

  const openTransactionDetailsView = () => {
    transaction.action === 'SELL'
      ? toast('Invoice for selling gold is not created', {
          duration: 2000,
          icon: '📝',
        })
      : setOpen(true);
  };

  const closeTransactionDetailsView = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        className="flex justify-between items-center border border-[#00000014] rounded-lg px-3 py-4 gap-x-3 w-full bg-white my-3 cursor-pointer"
        onClick={openTransactionDetailsView}
      >
        <Calender epochTime={new Date(transaction.date).getTime()} />

        <div className="flex items-center justify-between gap-5 w-full">
          <div className="flex flex-col items-start justify-between gap-1">
            <p className={'text-stone-400 text-xs font-medium leading-[18px]'}>
              {transaction.action === 'SELL' ? 'Sold' : 'Bought'} {transaction.metal || ''}
            </p>
            <p className={'text-stone-700 text-sm font-medium leading-[21px]'}>
              {transaction.action === 'SELL' ? 'Sold ' : 'Added '}
              {transaction.quantity}gm
            </p>
            <div className="flex items-center justify-between gap-x-1">
              <p
                className={`${
                  transaction.status === 'COMPLETED'
                    ? 'text-green-700'
                    : transaction.status === 'FAILED'
                    ? 'text-red-900'
                    : 'text-stone-900'
                }  text-xs font-medium leading-[18px] capitalize`}
              >
                {transaction.status?.toLocaleLowerCase().split('_').join(' ') || ''}
              </p>
              <span className="block h-1 w-1 bg-stone-400 rounded-full"></span>
              <p className={'text-stone-400 text-xs font-medium leading-[18px]'}>
                For {transaction.clientName || ''}
              </p>
            </div>
          </div>
          <p
            className={`${
              transaction.status === 'COMPLETED' ? 'text-green-700' : 'text-stone-900'
            }  text-sm font-medium leading-[21px]`}
          >
            {getRupeeWithDecimal.format(transaction.amount) || 0}
          </p>
        </div>
      </div>

      <TransactionDetailsCard
        open={open}
        onClose={closeTransactionDetailsView}
        transaction={transaction}
      />
    </>
  );
};
