import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { NetworkManager } from './services';
import ProtectedRoutes from './pages/Login/ProtectedRoutes';
import { useTokenMessageHandler } from './hooks/useTokenMessageHandler';
import Deals from './pages/Deals/Deals';
import Layout from './components/organisms/Layout/Layout';
import DealDetail from './pages/DealDetail/DealDetail';
import Onboarding from './pages/Onboarding/Onboarding';
import { Toaster } from 'react-hot-toast';
import Clients from './pages/Clients/Clients';
import ClientDetail from './pages/ClientDetail/ClientDetail';
import Investments from './pages/Investments/Investments';
import Home from './pages/Home/Home';
import Terms from './pages/Terms/Terms';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import RiskDisclosure from './pages/RiskDisclosure/RiskDisclosure';
import Profile from './pages/Profile/Profile';
import ProductDeck from './pages/ProductDeck/ProductDeck';
import TapAcademy from './pages/TapAcademy/TapAcademy';
import UserPayment from './pages/UserPayment/UserPayment';
import BondsDealDetail from './pages/BondsDealDetail/BondsDealDetail';
import { BONDS_DEALS_ENABLED } from './flags';
import Partners from './pages/Partners/Partners';
import PartnersDetail from './pages/partnersDetail/PartnersDetail';
import FamilyAccount from './pages/familyAccount/FamilyAccount';
import FamilyDetails from './pages/FamilyDetail/FamilyDetails';
import Faq from './components/organisms/FAQPage/Faq';
import { useProfile } from './context/ProfileContext';
import GlobalLoadingPage from './components/LoadingPage/GlobalLoadingPage';
import BondsPTCDealDetail from './pages/BondsDealDetail/BondsPTCDealDetail';

async function validateTokenAndFetchProfile(navigate, setIsNotReady, updateProfileData) {
  const token = localStorage.getItem('token');
  const currentPath = window.location.pathname;
  const fullPath = window.location.pathname + window.location.search;

  if (token) {
    try {
      const profileResponse = await NetworkManager.Call({
        method: 'GET',
        path: 'profile/v1/get-partner-config',
        retry: 3,
      });

      const profileData = await profileResponse?.data;
      updateProfileData(profileData);

      if (profileData?.type === 'PARTNER' && ['/', '/signup', '/login'].includes(currentPath)) {
        navigate('/deals');
      } else {
        navigate(fullPath);
      }
    } catch (error) {
      console.error(error?.response?.data?.message || 'An error occurred');
      navigate(fullPath);
    } finally {
      setIsNotReady(false);
    }
  } else {
    setIsNotReady(false);
  }
}

function App() {
  const navigate = useNavigate();
  const [isNotReady, setIsNotReady] = useState(true);
  const { updateProfileData, profileData } = useProfile();

  const onTokenSet = async () => {
    setIsNotReady(true);
    await validateTokenAndFetchProfile(navigate, setIsNotReady, updateProfileData);
  };

  useTokenMessageHandler(navigate, onTokenSet);

  useLayoutEffect(() => {
    (async () => {
      const token = localStorage.getItem('token');
      if (token) {
        await validateTokenAndFetchProfile(navigate, setIsNotReady, updateProfileData);
      } else {
        setIsNotReady(false);
      }
    })();
  }, [localStorage.getItem('token')]);

  if (isNotReady) return <GlobalLoadingPage />;
  return (
    <div>
      <NetworkManager.CustomQueryClientProvider>
        <Toaster />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/risk-disclosure" element={<RiskDisclosure />} />
          <Route path="/faq" element={<Faq />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="*" element={<Navigate to="/deals" replace={true} />} />
            <Route element={<Layout />}>
              {profileData?.features?.enableClient && (
                <Route path="/clients" element={<Clients />} />
              )}
              {profileData?.features?.enableMasterPartner && (
                <Route path="/partners" element={<Partners />} />
              )}
              {profileData?.features?.enableProfileScreen && (
                <Route path="/profile" element={<Profile />} />
              )}
              {profileData?.features?.enableClient && (
                <Route path="/clients/:clientId" element={<ClientDetail />} />
              )}
              {profileData?.features?.enableFamilyAccount && (
                <Route path="/family-account" element={<FamilyAccount />} />
              )}
              {profileData?.features?.enableFamilyAccount && (
                <Route path="/family-account/:familyAccountId" element={<FamilyDetails />} />
              )}
              {profileData?.features?.enableMasterPartner && (
                <Route path="/partners/:partnerId" element={<PartnersDetail />} />
              )}
              <Route path="/deals" element={<Deals />} />
              <Route path="/deals/:dealId" element={<DealDetail />} />
              {BONDS_DEALS_ENABLED && (
                <Route path="/bond-deals/:dealId" element={<BondsDealDetail />} />
              )}
              {BONDS_DEALS_ENABLED && (
                <Route path="/bond-deals/PTC/:dealId" element={<BondsPTCDealDetail />} />
              )}
              <Route path="/deals/:dealId/:financeType" element={<DealDetail />} />
              <Route path="/investments" element={<Investments />} />
              {profileData?.features?.enableTapAcademy && (
                <Route path="/tap-academy" element={<TapAcademy />} />
              )}
              {profileData?.features?.enableProductResources && (
                <Route path="/product-resources" element={<ProductDeck />} />
              )}
            </Route>
          </Route>

          <Route path="/login" element={<Onboarding />} />
          <Route path="/user-payment/:productType" element={<UserPayment />} />
        </Routes>
      </NetworkManager.CustomQueryClientProvider>
    </div>
  );
}

export default App;
