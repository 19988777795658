import React from 'react';
import dayjs from 'dayjs';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import NorthWestIcon from '@mui/icons-material/NorthWest';

type Transaction = {
  type: 'CREDIT' | 'DEBIT';
  amount: number;
  balance: number;
  transactionState: string;
  timestamp: string;
  purpose: string;
};

type TransactionsProps = {
  content: Transaction[];
};

const TransactionList: React.FC<TransactionsProps> = ({ content }) => {
  return (
    <div className="space-y-4">
      {content.length > 0 ? (
        content.map((transaction, index) => (
          <div
            key={index}
            className="flex flex-row items-start p-[17px_12px_17px_0] gap-3 border-b border-black/[0.08] shadow-[0px_1px_12px_rgba(0,0,0,0.03)]"
          >
            <div className="flex items-center">
              {/* Arrow Icon based on the type (CREDIT/DEBIT) */}
              <div className="py-2 px-4">
                {transaction.type === 'CREDIT' ? <SouthEastIcon /> : <NorthWestIcon />}
              </div>

              <div className="flex flex-col gap-2">
                <span className="text-stone-700 text-sm font-medium">
                  {transaction.purpose === 'RECHARGE'
                    ? 'Money Added to Wallet'
                    : transaction.purpose}
                </span>
                <span className={`text-xs font-medium text-stone-400`}>
                  <span
                    className={
                      transaction.transactionState === 'SUCCESS'
                        ? 'text-green-700'
                        : transaction.transactionState === 'FAILED'
                        ? 'text-red-600'
                        : transaction.transactionState === 'INITIATED'
                        ? 'text-blue-700'
                        : 'text-stone-400'
                    }
                  >
                    {transaction.transactionState.charAt(0) +
                      transaction.transactionState.slice(1).toLowerCase()}
                  </span>

                  {' • '}
                  {dayjs(transaction.timestamp).format('DD MMMM YYYY • hh:mm A')}
                </span>
              </div>
            </div>

            <div className="flex flex-col items-end ml-auto gap-2">
              <span className="text-green-700 text-sm font-medium">
                ₹{transaction.amount.toLocaleString()}
              </span>
              <span className="text-stone-400 text-xs font-medium">
                Wallet Bal. {transaction.balance.toLocaleString()}
              </span>
            </div>
          </div>
        ))
      ) : (
        <div className="flex justify-center">
          <div className="text-center mt-10">
            No transactions found, add money by clicking on 'Wallet'
          </div>
        </div>
      )}
    </div>
  );
};

export default TransactionList;
