import React from 'react';
import RbiIcon from '../../../assets/rbi.png';
import LendboxIcon from '../../../assets/lendbox.png';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

interface AboutGoldProps {
  data: {
    about: string | null;
    amount: number | null;
    email: string | null;
    expiryDateTime: number | null;
    interest: string | null;
    isin: string | null;
    key: string | number | null;
    logo: string | null;
    maturityDate: string | null;
    miniSummary: string | null;
    name: string | null;
    partnerName: string | null;
    phone: string | null;
    principal: string | null;
    qty: number | null;
    returnAmount: number | null;
    settlementDate: string | null;
    unitConsiderationAmount: number | null;
    dealName: string | null;
    yield: number | null;
    tags: string | null;
    investmentResponseDTO: {
      financeType: string | null;
      investmentId: number | null;
      ipAddress: string | number | null;
      meta: {
        size: string | number | null;
        pageNumber: string | number | null;
        totalPages: string | number | null;
        totalElements: string | number | null;
      };

      payment: {
        paymentOperator: string | null;
        paymentOrderId: number | null;
        paymentSessionId: string | null;
      };
      paymentMode: string | null;
    };
  };
}

const AboutGold: React.FC<AboutGoldProps> = ({ data }) => {
  return (
    <div className="px-5 mt-10 rounded-md max-w-xl flex flex-col gap-6">
      {/* <div className="border border-amber-700 p-8 rounded-xl bg-[#fbfbf6] relative">

        <div className="absolute top-0 left-0 w-full h-full flex flex-wrap gap-10 overflow-hidden">
          {[...Array(8)].map((_, index) => (
            <div
              key={index}
              className="w-12 h-full rounded-l-xl"
              style={{
                opacity: '0.11',
                background: 'linear-gradient(180deg, #D97706 0%, rgba(251, 251, 246, 0.00) 100%)',
                filter: 'blur(17.5px)',
                transform: 'rotate(-30.369deg)',
              }}
            ></div>
          ))}
        </div>

        <h2 className="font-semibold text-2xl text-amber-700">Earn upto 0.5% per annum</h2>
        <h3 className="text-stone-500 mt-2 text-base">Grow more, withdraw anytime!</h3>
        <div className="bg-stone-200 flex items-center absolute -bottom-6 left-0 rounded-md -z-10 text-stone-500 font-medium text-xs pt-4 px-3 pb-1">
          Powered by
          Augmont
        </div>
      </div> */}
      <div className="flex flex-col">
        <p className="text-sm text-gray-500">
          Buy digital gold to enhance your client’s portfolio and get returns as the gold prices
          surge. Withdraw up to certain limit quickly.
        </p>
        <p className="my-4 text-stone-500 font-normal text-sm">
          Invest in <span className="text-amber-700 font-medium">24k at 99%</span> purity gold,
          right from your fingertips
        </p>
      </div>

      <div>
        <h2 className="text-sm font-normal text-stone-500">About AUGOMENT:</h2>
        <p className="text-sm text-stone-500 mt-3">
          Augmont is NABL and BIS accredited, and a member of the India Good Delivery standard on
          NSE, BSE, and MCX. With its expertise in gold and unwavering commitment to quality,
          Augmont has earned the trust of millions of users.
        </p>
      </div>
    </div>
  );
};

export default AboutGold;
