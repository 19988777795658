import React, { useCallback, useEffect, useMemo, useState } from "react";
import KYCButton from "../Onboarding/KYCButton";
import SelectClient from "./SelectClient";
import {
  getRupee,
  getRupeeWithDecimal,
  twoDecimalNumber,
} from "../../utils/numbers";
import { formatDate } from "../../utils/date";
import Input from "../../components/Fields/Input";
import useGetPartnerProfile, {
  getCreateP2PPaymentLink,
} from "../../hooks/useGetPartnerProfile";
import { NetworkManager } from "../../services";
import { kycError } from "../../utils/toast";
import { CloseOutlinedIcon, Dialog, InfoIcon } from "../../assets/mui";
import clsx from "clsx";
import { useKycStatusP2P, useNudgesP2P } from "../Deals/useDeal";
import NudgeIcon from "../../assets/nudge.png";
import RbiIcon from "../../assets/rbi.png";
import LendboxIcon from "../../assets/lendbox.png";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import { Field } from "../../components/Fields/Field";
import { DialogContent, DialogTitle } from "@mui/material";
import { DEAL_EVENTS } from "../Deals/constants";
import { useAnalytics } from "../../hooks/useAnalytics";

interface TapReserveProps { }

const aadhaarSchema = yup
  .string()
  .matches(/^[0-9]{12}$/, "Invalid Aadhaar number format")
  .required("Please enter Aadhaar Number");

const incorrectOtp = () =>
  toast.error("Invalid OTP", {
    duration: 2500,
    position: "top-center",
  });

const TapReserve: React.FC<TapReserveProps> = () => {
  const [isInvestmentLoading, setInvestmentLoading] = useState(false);
  const [plan, setPlan] = useState<"TAP_3M_FIXED" | "TAP_6M_FIXED">(
    "TAP_3M_FIXED"
  );
  const [client, setClient] = useState<any>(null);
  const [amountError, setAmountError] = useState(null);
  const [maxAmount, setMaxAmount] = useState(0);
  const [investmentAmount, setInvestmentAmount] = useState<string>("50000");
  const [totalReturns, setTotalReturns] = useState(null);
  const [investmentTerms, setInvestmentTerms] = useState(null);
  const [values, setValues] = useState([""]);
  const [isKycOpen, setIsKycOpen] = useState(false);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [isKycComplete, setIsKycComplete] = useState(false);
  const [isOtpResend, setIsOtpResend] = useState(false);
  const [timeForOtpResend, setTimeForOtpResend] = useState(30);
  const [kycStep, setKycStep] = useState<"info" | "aadhaar" | "otp" | "done">(
    "info"
  );
  const [userPaymentLink, setUserPaymentLink] = useState(null);

  const { track } = useAnalytics();

  const clientId = client?.clientPublicId;

  const { data: partner } = useGetPartnerProfile();

  const { data: nudgesData } = useNudgesP2P();

  const { data: kycStatus } = useKycStatusP2P(clientId);

  const partnerId = partner?.data?.profile?.publicId;
  const nudges = nudgesData?.data || [];

  // TODO: Use once payment link is back
  const isClientWalletEnough = useMemo(() => {
    return (
      client?.balance >= investmentTerms?.minimumInvestment &&
      client?.balance >= parseInt(investmentAmount)
    );
  }, [investmentTerms, client?.balance, investmentAmount]);

  const schema = yup.object().shape({
    otp: yup.number().when("aadhaarSaved", ([aadhaarSaved]) => {
      if (aadhaarSaved) {
        return yup
          .number()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .max(999999, "Must be a 6-digit OTP")
          .min(100000, "Must be a 6-digit OTP")
          .required("Please enter OTP");
      } else {
        return yup
          .number()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .nullable();
      }
    }),
    aadhaar: aadhaarSchema,
    aadhaarSaved: yup.bool().default(false),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { aadhaar: null },
  });

  const submitAadhaar = (aadhaar: string) => {
    return NetworkManager.Call({
      method: "post",
      path: NetworkManager.PATH.p2pAadhaar,
      data: {
        aadhaarNumber: aadhaar,
        clientId,
        partnerId,
      },
    });
  };

  const submitOTP = (otp: number) => {
    return NetworkManager.Call({
      method: "post",
      path: NetworkManager.PATH.p2pOtp,
      data: {
        otp,
        clientId,
      },
    });
  };
  const handleKycClose = () => setIsKycOpen(false);

  const handlePaymentClose = () => {
    setIsPaymentOpen(false);
    setUserPaymentLink(null);
  };

  const handleInvestmentTerms = useCallback(
    async (amount?: number) => {
      const investmentResponse = await NetworkManager.Call({
        method: "get",
        path: NetworkManager.PATH.p2pInvestments,
        params: {
          amount: amount || investmentAmount,
          type: plan,
        },
      });

      setTotalReturns(investmentResponse?.data?.totalProjectedValue || null);

      setInvestmentTerms(investmentResponse.data);

      return investmentResponse;
    },
    [plan, investmentAmount]
  );

  const onSubmit = async (e) => {
    e.preventDefault();
    setInvestmentLoading(true);
    if (isKycComplete) {
      track({ eventName: DEAL_EVENTS.Tap_Reserve_Generate_Payment_Link });
      getCreateP2PPaymentLink({
        clientPublicId: client?.clientPublicId,
        amount: investmentAmount,
        investmentType: plan,
        totalProjectedValue: totalReturns,
        returns: investmentTerms?.returns,
        payoutDate: investmentTerms?.payoutDate,
      })
        .then((res) => {
          setUserPaymentLink(res?.data);
          setIsPaymentOpen(true);
        })
        .catch((error) => {
          toast.error(
            `${error?.response?.data?.message ||
            "Something went wrong! Please try again"
            }`,
            {
              duration: 2500,
              position: "top-center",
            }
          );
        });
    } else {
      track({ eventName: DEAL_EVENTS.Tap_Reserve_Complete_KYC });
      if (investmentAmount && clientId && partnerId) {
        setIsKycOpen(true);
      }
    }
    setInvestmentLoading(false);
  };

  const handleInvestmentAmount = useCallback(
    async (e) => {
      const investmentAmount = e.target.value;
      const sanitizedAmount = investmentAmount.replace(/[^0-9]/g, "");
      let amount = twoDecimalNumber(sanitizedAmount);

      setInvestmentAmount(amount);

      if (maxAmount && parseInt(amount) >= maxAmount) {
        setAmountError(
          `Max investment amount is ₹${twoDecimalNumber(maxAmount)}`
        );
      } else {
        setAmountError(null);
        if (!amount || amount == "0") {
          setAmountError(null);
          setTotalReturns(null);
        } else if (parseInt(amount) < investmentTerms?.minimumInvestment) {
          setAmountError("Less than minimum investment amount");
          setTotalReturns(null);
        } else {
          const investmentResponse = await handleInvestmentTerms(
            parseInt(amount)
          );

          const totalReturns = investmentResponse?.data?.totalProjectedValue;
          setAmountError(null);
          setMaxAmount(investmentResponse?.data?.maximumInvestment);
          if (totalReturns) {
            setTotalReturns(totalReturns);
          }
          // const commission = investmentResponse?.data?.result?.commission;
          // if (commission) {
          //     setCommission(commission);
          // }
          // const commissionRate = investmentResponse?.data?.result?.commissionPercentage;
          // if (commissionRate) {
          //     setCommissionRate(commissionRate);
          // }
        }
      }
    },
    [investmentTerms?.minimumInvestment, maxAmount, investmentAmount]
  );

  const handleKycStart = () => {
    if (kycStep == "info") {
      setKycStep("aadhaar");
    }
    if (kycStep == "done") {
      handleKycClose();
      setIsKycComplete(true);
    }
  };

  const handleP2PSubmit = async (data: Record<string, any>) => {
    const { aadhaar, otp } = data;

    if (kycStep == "aadhaar") {
      try {
        const aadhaarRes = await submitAadhaar(aadhaar);
        if (!aadhaarRes.error) {
          setValue("aadhaarSaved", true);
          setValue("otp", null);
          setKycStep("otp");
        } else {
          kycError("Something went wrong! Please try again");
        }
      } catch (error) {
        kycError(error?.response?.data?.message);
      }
    }

    if (kycStep == "otp") {
      try {
        const otpRes = await submitOTP(otp);
        if (!otpRes.error) {
          setKycStep("done");
          setIsKycComplete(true);
        } else {
          kycError("Something went wrong! Please try again");
          setValue("aadhaar", aadhaar);
        }
      } catch (error) {
        if (error.response.status == 401) {
          incorrectOtp();
        }
      }
    }

    if (kycStep == "done") {
      setIsKycComplete(true);
    }
  };

  const handleResend = async () => {
    if (isOtpResend && kycStep == "otp") {
      try {
        const aadhaarRes = await submitAadhaar(getValues("aadhaar"));
        if (!aadhaarRes.error) {
          setValue("aadhaarSaved", true);
          setValue("otp", null);
          setKycStep("otp");
          setTimeForOtpResend(30);
          setIsOtpResend(false);
        } else {
          kycError("Something went wrong! Please try again");
        }
      } catch (error) {
        kycError(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    handleInvestmentTerms();
  }, [plan]);

  useEffect(() => {
    if (timeForOtpResend > 0) {
      const timerId = setTimeout(() => {
        setTimeForOtpResend(timeForOtpResend - 1);
      }, 1000);
      return () => clearTimeout(timerId);
    } else {
      setIsOtpResend(true);
    }
  }, [timeForOtpResend]);

  useEffect(() => {
    if (!!kycStatus) {
      setIsKycComplete(kycStatus?.data);
    }
  }, [kycStatus]);

  useEffect(() => {
    let index = 0;

    const interval = setInterval(() => {
      index = (index + 2) % nudges.length;
      setValues([nudges[index], nudges[(index + 1) % nudges.length]]);
    }, 4000);

    return () => clearInterval(interval);
  }, [nudges]);

  return (
    <div className="my-6 max-w-7xl mx-auto">
      <div className="grid grid-cols-2 xl:grid-cols-5 gap-8 xl:gap-16">
        <div className="col-span-3">
          {/* Lendbox button */}
          <div className="bg-green-900 relative p-8 rounded-xl">
            <h2 className="font-semibold text-2xl text-white">Earn upto 12% per annum</h2>
            <h3 className="text-white/50 mt-2">Daily returns, withdraw anytime!</h3>
            <div className="bg-stone-200 flex items-center absolute -bottom-6 left-0 rounded-md -z-10 text-stone-500 font-medium text-xs pt-4 px-3 pb-1">
              Powered by
              <img src={LendboxIcon} alt="Lendbox" width={16} height={16} className="mx-1" />
              Lendbox
            </div>

            <a
              href="https://tapinvest.freshdesk.com/support/solutions/folders/84000349318"
              target="_blank"
              className="absolute h-6 w-6 flex justify-center items-center bg-white rounded-full top-4 right-4"
            >
              <QuestionMarkIcon fontSize="small" className="text-green-900" />
            </a>
          </div>

          {/* Description */}
          <div className="mt-14 text-stone-500">
            <p>
              Build a P2P portfolio with Tap Reserve and India’s largest RBI regulated P2P NBFC,
              Lendbox
            </p>
            <p className="mt-3 text-sm mb-7">About Lendbox:</p>

            <div className="flex gap-8 text-black/50">
              <div className="flex flex-col gap-8">
                <div>
                  <h3 className="text-lg sm:text-2xl md:text-[32px] mb-2">4.7 Lakh+</h3>
                  <p className="text-xs sm:text-sm md:text-lg">Investors till date</p>
                </div>
              </div>
              <div className="border-x border-x-stone-200 px-4 sm:px-8">
                <div className="flex flex-col gap-8">
                  <div>
                    <h3 className="text-lg sm:text-2xl md:text-[32px] mb-2">2,563 Cr+</h3>
                    <p className="text-xs sm:text-sm md:text-lg">Assets Under Management</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex flex-col gap-8">
                  <div>
                    <h3 className="text-lg sm:text-2xl md:text-[32px] mb-3 md:mb-1">
                      <img
                        src={RbiIcon}
                        alt="rbi"
                        width={38}
                        height={38}
                        className="w-6 h-6 md:w-9 md:h-9"
                      />
                    </h3>
                    <p className="text-xs sm:text-sm md:text-lg">RBI Regulated</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Instructions */}
          <div className="mt-14">
            <h3 className="font-semibold text-xl mb-6">Payment instructions for Tap Reserve</h3>
            <ul className="list-disc flex flex-col gap-2 text-stone-500 ml-4">
              <li>
                <b>Wallet Transactions Not Available:</b> The platform does not allow P2P
                investments using your existing wallet balance.
              </li>
              <li>
                <b>Payment Link for Investment:</b> For P2P investment a secure payment link will be
                generated.
              </li>
              <li>
                <b>Sharing the Link:</b> Please share this payment link to your chosen investor
                (client).
              </li>
              <li>
                <b>Investment Success:</b> Once your investor completes the payment via the link,
                your P2P investment will be successfully processed.
              </li>
            </ul>
          </div>

          {/* Nudges */}
          {nudges.length > 0 && (
            <div className="mt-8">
              <h3 className="font-semibold text-xl mb-6">Did you know?</h3>
              <div className="bg-white flex justify-start items-center text-stone-500 text-sm min-h-12 w-fit align-middle rounded-xl px-3 py-1.5 shadow-sm">
                <img src={NudgeIcon} alt="Nudge" width={28} height={28} />
                <span className="ml-2">{values[0]}</span>
              </div>
            </div>
          )}
        </div>
        <form className="col-span-2" onSubmit={onSubmit}>
          <div className="mb-8">
            <h3 className="text-sm text-stone-700 mb-2">Select Plan</h3>
            <div className="flex gap-3">
              <button
                type="button"
                className={clsx('uppercase rounded py-1.5 px-3 font-semibold text-xs', {
                  'bg-green-700 text-white': plan == 'TAP_3M_FIXED',
                  'bg-stone-200 text-stone-500': plan == 'TAP_6M_FIXED',
                })}
                onClick={() => {
                  setPlan('TAP_3M_FIXED');
                  track({ eventName: DEAL_EVENTS.Tap_Reserve_3M_plan });
                }}
              >
                3 Months
              </button>
              <button
                type="button"
                className={clsx('uppercase rounded py-1.5 px-3 font-semibold text-xs', {
                  'bg-green-700 text-white': plan == 'TAP_6M_FIXED',
                  'bg-stone-200 text-stone-500': plan == 'TAP_3M_FIXED',
                })}
                onClick={() => {
                  setPlan('TAP_6M_FIXED');
                  track({ eventName: DEAL_EVENTS.Tap_Reserve_6M_plan });
                }}
              >
                6 Months
              </button>
            </div>
            <h4 className="font-medium text-sm text-stone-400 mt-3">
              Instantly provides daily returns, with no lock in.
            </h4>
          </div>
          <div className="flex flex-col bg-black/[.02] rounded-lg border border-stone-300">
            <div className="">
              {/* Order Investment Input */}
              <div className="border-b relative border-b-stone-300 text-slate-600 overflow-hidden h-24">
                <label className="absolute z-10 top-4 left-3 tracking-wider uppercase text-xs font-semibold text-black/40">
                  Investment amount
                </label>
                <Input
                  value={investmentAmount ? parseFloat(investmentAmount) : ''}
                  prefixIcon={<span className="text-black/40 font-medium text-xl pt-3">₹</span>}
                  onClick={() => {
                    track({ eventName: DEAL_EVENTS.Tap_Reserve_enter_amount });
                  }}
                  type="text"
                  placeholder={investmentTerms?.minimumInvestment || ''}
                  className="h-full font-semibold text-2xl placeholder:text-stone-400 pt-6 border-none rounded-b-none"
                  containerClassName="h-full border-none rounded-b-none"
                  onChange={handleInvestmentAmount}
                />
                {!!amountError && (
                  <span className="text-red-500 text-xs mt-1 absolute bottom-3 left-8">
                    {amountError}
                  </span>
                )}
              </div>
              <div className="p-4 md:p-5 border-b border-b-stone-300 flex items-center gap-4 text-slate-600">
                <h3 className="text-sm min-w-36">Projected Returns</h3>
                <span className="text-lg font-medium line-clamp-1">
                  {totalReturns
                    ? getRupeeWithDecimal.format(totalReturns)
                    : getRupeeWithDecimal.format(investmentTerms?.totalProjectedValue)}
                </span>
              </div>
              {/* <div className="p-4 md:p-5 border-b border-b-stone-300 flex items-center gap-4 text-slate-600">
                                <h3 className="text-sm min-w-36 font-semibold text-green-700">Your Commission</h3>
                                <span className="text-lg font-semibold text-green-700 line-clamp-1">{commission ? getRupeeWithDecimal.format(commission) : getRupeeWithDecimal.format(initialCommission)}  <span className="font-medium text-sm text-stone-400">({commissionRate ? commissionRate : commissionPercentage}%)</span></span>
                            </div> */}
              <div className="p-4 md:p-5 border-b border-b-stone-300 flex items-center gap-4 text-slate-600">
                <h3 className="text-sm min-w-36">Minimum Investment</h3>
                <span className="text-lg font-medium">
                  {getRupee.format(investmentTerms?.minimumInvestment)}
                </span>
              </div>
              <div className="p-4 md:p-5 border-b border-b-stone-300 flex items-center gap-4 text-slate-600">
                <h3 className="text-sm min-w-36">Returns p.a.</h3>
                <span className="text-lg font-medium">
                  {twoDecimalNumber(investmentTerms?.returns)}%
                </span>
              </div>
              <div className="p-4 md:p-5 flex items-center gap-4 text-slate-600">
                <h3 className="text-sm min-w-36">Payout on</h3>
                <span className="text-lg font-medium">
                  {formatDate(investmentTerms?.payoutDate)}
                </span>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <SelectClient
              client={client}
              setClient={setClient}
              eventName={DEAL_EVENTS.Tap_reserve_Client_select}
            />
          </div>
          {/* {!!client && client?.balance < parseInt(investmentAmount) &&
                        <p className="text-amber-700 mt-1.5 mb-2 text-sm font-semibold flex items-center gap-1"><InfoIcon fontSize="small" /> There’s insufficient money in the client’s wallet</p>
                    } */}
          <div className="mt-4 flex justify-between items-center gap-2.5">
            {/* {!!client && !isClientWalletEnough &&
                            <button
                                onClick={handleWalletOpen}
                                type="button"
                                className="bg-white shadow flex-shrink-0 flex items-center gap-2 text-sm text-gray-700 border border-gray-300 rounded-xl font-semibold h-10 w-fit px-4"
                            >
                                View Wallet Details
                            </button>
                        } */}
            {isKycComplete ? (
              <KYCButton
                text="Generate Payment Link"
                isLoading={isInvestmentLoading}
                className="!mt-0 h-10 !py-0 shadow"
                disabled={
                  !investmentAmount ||
                  parseInt(investmentAmount) > investmentTerms?.maximumInvestment ||
                  !clientId ||
                  !partnerId ||
                  !!amountError
                }
              />
            ) : (
              <KYCButton
                text="Complete your KYC with Lendbox"
                isLoading={isInvestmentLoading}
                className="!mt-0 h-10 !py-0 shadow"
                // use isClientWalletEnough once payment link integration
                disabled={
                  !investmentAmount ||
                  parseInt(investmentAmount) < investmentTerms?.minimumInvestment ||
                  !clientId ||
                  !partnerId ||
                  !!amountError
                }
              />
            )}
          </div>
        </form>
      </div>

      {/* KYC Dialog */}
      <Dialog open={isKycOpen} maxWidth="sm" fullWidth onClose={handleKycClose}>
        <form onSubmit={kycStep !== 'info' && handleSubmit(handleP2PSubmit)}>
          <div className="p-5 border bg-primary border-zinc-200">
            <h3 className="text-lg font-semibold">
              {kycStep == 'info' && `Validate KYC for ${client?.name}`}
              {(kycStep == 'aadhaar' || kycStep == 'otp') && 'Aadhaar Details'}
              {kycStep == 'done' && `KYC completed for ${client?.name}`}
            </h3>
            <p className="text-slate-600 text-sm mt-1.5">
              {kycStep == 'info' &&
                'As per RBI P2P Regulations, Lendbox is required to validate your client’s KYC before investing.'}
              {(kycStep == 'aadhaar' || kycStep == 'otp') &&
                'An OTP will be sent to your client’s Aadhaar registered phone number'}
              {kycStep == 'done' &&
                'You have successfully completed your client’s KYC Verification with Lendbox.'}
            </p>
          </div>
          {kycStep !== 'done' && (
            <div className="p-5 flex bg-primary flex-col gap-3">
              {kycStep == 'info' && (
                <>
                  <h4 className="font-medium text-gray-700">Your client details</h4>
                  <div className="flex items-center">
                    <h4 className="text-sm text-gray-600 w-36">Pan Number</h4>
                    <h3 className="text-sm font-medium">{client?.pan}</h3>
                  </div>
                  <div className="flex items-center">
                    <h4 className="text-sm text-gray-600 w-36">Phone Number</h4>
                    <h3 className="text-sm font-medium">{client?.phone}</h3>
                  </div>
                  <div className="flex items-center">
                    <h4 className="text-sm text-gray-600 w-36">Email ID</h4>
                    <h3 className="text-sm font-medium">{client?.email}</h3>
                  </div>
                </>
              )}

              {kycStep == 'aadhaar' && (
                <>
                  <Field label="Enter your Aadhaar Number" error={errors.aadhaar?.message}>
                    <Input
                      type="number"
                      placeholder="1234 4567 8797"
                      numericOnly={true}
                      {...register('aadhaar', {
                        required: true,
                        maxLength: 12,
                      })}
                    />
                  </Field>
                </>
              )}

              {kycStep == 'otp' && (
                <>
                  <Field label="Enter OTP" error={errors.otp?.message}>
                    <Input
                      type="number"
                      placeholder="Enter OTP"
                      numericOnly={true}
                      {...register('otp')}
                    />
                  </Field>
                </>
              )}
            </div>
          )}
          <div className="p-5 bg-primary flex justify-end items-center gap-3">
            {kycStep == 'otp' && (
              <button
                disabled={!isOtpResend}
                type="button"
                onClick={handleResend}
                className={clsx(
                  'bg-white text-sm font-semibold px-7 rounded-xl text-black/80 shadow border border-stone-300 h-10',
                )}
              >
                Resend OTP {!isOtpResend ? timeForOtpResend : ''}
              </button>
            )}
            <button
              className="bg-green-700 text-sm font-semibold px-7 rounded-xl text-white h-10"
              type={kycStep == 'info' ? 'button' : 'submit'}
              onClick={kycStep == 'info' || kycStep == 'done' ? handleKycStart : undefined}
            >
              {kycStep == 'info' && 'Continue Verification'}
              {kycStep == 'aadhaar' && 'Submit'}
              {kycStep == 'otp' && 'Submit OTP'}
              {kycStep == 'done' && 'Close'}
            </button>
          </div>
        </form>
      </Dialog>

      {/* Payment Link Dialog */}
      {/* <Dialog
        open={isPaymentOpen}
        maxWidth="sm"
        fullWidth
        onClose={handlePaymentClose}
      >
        <div className="p-5 bg-white rounded-lg">
          <div className="p-3 mb-6 flex items-center justify-between w-14 h-14 rounded-full bg-orange-100">
            <LinkIcon className="text-orange-700 flex m-auto" />
          </div>
          <h3 className="text-xl font-semibold">
            Request for the Payment Link
          </h3>
          <p className="text-stone-600 mt-4 font-medium">
            Our team will contact you soon to share the payment link which you
            can send it to your client for successful investment.
          </p>
        </div>
      </Dialog> */}

      {/* Client Generate Payment Link */}
      <Dialog open={isPaymentOpen} maxWidth="sm" fullWidth>
        <div className="flex bg-amber-100 p-2.5 items-center gap-2 ">
          <InfoIcon
            className="text-amber-700"
            sx={{
              height: '16px',
              width: '16px',
            }}
          />
          <h4 className="text-sm text-amber-700 font-medium">
            This link is valid only for 6 hours
          </h4>
        </div>
        <DialogTitle>
          <div className="px-5 flex justify-between">
            <div>
              <h3 className="text-lg font-semibold">Link Generated</h3>
              <p className="text-slate-600 text-sm mt-1.5">
                Copy link & share it with client for successful investment in Tap Reserve
              </p>
            </div>
            <CloseOutlinedIcon
              className="cursor-pointer bg-slate-200 rounded-full p-1"
              onClick={() => handlePaymentClose()}
              style={{
                width: '28px',
                height: '28px',
              }}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="py-5bg-white rounded-lg">
            <div className="p-5 pb-3 flex gap-3">
              <div
                className="flex flex-col justify-between gap-[6px]  flex-1"
                style={{
                  padding: '14px 16px 16px 0px',
                  borderRight: '1px solid #f0f0f0',
                }}
              >
                <h4 className="text-sm text-stone-400 ">INVESTMENT AMT.</h4>
                <h3 className="text-sm font-medium">₹{investmentAmount}</h3>
              </div>
              <div
                className="flex flex-col justify-between gap-[6px] flex-1"
                style={{ padding: '14px 16px 16px 24px' }}
              >
                <h4 className="text-sm text-stone-400">TENURE</h4>
                <h3 className="text-sm font-medium">
                  {plan === 'TAP_6M_FIXED' ? '6 months' : '3 months'}
                </h3>
              </div>
            </div>
            <div className="px-5 pt-6 pb-3">
              <div className="flex flex-col gap-[6px]">
                <h4 className="text-sm text-gray-600">Share link with your client</h4>
                <div className="flex items-center w-full">
                  <input
                    type="text"
                    value={`${userPaymentLink || 'loading link...'}`}
                    readOnly
                    className="flex-grow border border-gray-300 rounded-l-xl p-2"
                  />
                  <button
                    // onClick={copyToClipboard}
                    onClick={() => {
                      navigator.clipboard.writeText(userPaymentLink);
                      toast.success('Link copied to clipboard', {
                        duration: 2000,
                        position: 'top-center',
                      });
                    }}
                    className="bg-green-700 text-white text-sm font-semibold h-[2.65rem] px-4 rounded-r-xl "
                  >
                    Copy Url
                  </button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

TapReserve.displayName = "TapReserve";

export default TapReserve;
