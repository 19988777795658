import React from 'react';
import { getMonthDayDateForCalender } from '../../utils/date';

type CalenderProps = {
  epochTime?: number;
};

const Calender: React.FC<CalenderProps> = ({ epochTime = '' }) => {
  const date = getMonthDayDateForCalender(epochTime);
  return (
    <div className="border border-[#00000014] rounded-md p-0.5 flex flex-col items-center gap-2 uppercase self-stretch">
      <div className="w-[40px]">
        <div className="bg-[#00000005] rounded flex items-center justify-center uppercase py-0.5">
          <p className="text-stone-500 text-[10px] font-normal leading-[15px]">{date?.month}</p>
        </div>
        <div className="flex items-center justify-center">
          <h6 className="text-stone-900 text-sm font-semibold leading-[21px]">{date?.date}</h6>
        </div>
        <div className="flex items-center justify-center uppercase">
          <p className="text-stone-500 text-[10px] font-normal leading-[15px]">{date?.year}</p>
        </div>
      </div>
    </div>
  );
};

export default Calender;
