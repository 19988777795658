import { ChevronLeft } from '@mui/icons-material';
import React, { ReactNode } from 'react';

interface OTPWrapperProps {
  isVerifyStep: boolean;
  aadhaarOCRSelected?: boolean;
  title: string;
  subtitle: string;
  verifyTitle: string;
  verifySubtitle: string;
  resetVerify: () => void;
  children: ReactNode;
}

const OTPWrapper: React.FC<OTPWrapperProps> = ({
  isVerifyStep,
  aadhaarOCRSelected,
  title,
  subtitle,
  verifyTitle,
  verifySubtitle,
  resetVerify,
  children,
}) => {
  const currentTitle = isVerifyStep ? verifyTitle : title;
  const currentSubtitle = isVerifyStep ? verifySubtitle : subtitle;

  return (
    <div>
      <div>
        {(isVerifyStep || aadhaarOCRSelected) && (
          <div className={`min-h-10 ${aadhaarOCRSelected ? 'mb-4' : 'mb-6 md:mb-12'} `}>
            <button
              onClick={resetVerify}
              className="border border-stone-300 rounded-xl h-10 w-10 text-black/50"
            >
              <ChevronLeft />
            </button>
          </div>
        )}
        <h2 className="text-slate-800 text-2xl md:text-4xl font-medium">{currentTitle}</h2>
        <h3 className="text-gray-700 mt-3">{currentSubtitle}</h3>
      </div>

      <div className="mt-9">{children}</div>
    </div>
  );
};

OTPWrapper.displayName = 'OTPWrapper';

export default OTPWrapper;
