import React from 'react';

export const BONDS_FAQS = [
    {
        id: 0,
        question: "What is a Bond?",
        answer: "Bonds are a type of loan instrument, these are debt securities issued by the Government, private, and public corporations. Companies issue corporate bonds to raise money for a variety of purposes, such as building a new plant, purchasing equipment, or growing the business."
    },
    {
        id: 1,
        question: "What is the difference between Coupon & Yield?",
        answer: "Coupon rate of a bond is fixed for the complete tenure and does not change, yield changes depending on when and what price an investor is purchasing the bond."
    },
    {
        id: 2,
        question: "What do you mean by Senior secured Bonds?",
        answer: "Senior secured bond holders have the highest priority for repayment in case of default or bankruptcy proceedings. They are typically paid back before other creditors and equity holders."
    },
    {
        id: 3,
        question: "Do I need a demat account to Invest in bonds?",
        answer: "Yes, bonds are available in a dematerialized format, hence you need to have a demat account to hold them."
    },
    {
        id: 4,
        question: "How are bonds taxed?",
        answer: "Bonds are taxed under Income from other source (IFOS) method according to your tax slab."
    },
    {
        id: 5,
        question: "Is TDS deducted in Bonds?",
        answer: "Yes, there is a 10% TDS deduction on the interest component."
    },
    {
        id: 6,
        question: "How will repayment occur?",
        answer: "Tap Invest does not play a role in handling the repayments, the repayments happen directly from the issuer company's end, directly in your bank account according to the payout schedule."
    },
    {
        id: 7,
        question: "What are security components in terms of Bonds?",
        answer: <div>
            <ul className="list-disc pl-6 my-3">
                <li>Collateral or Security Interest: Some bonds are secured by specific assets of the issuer, such as real estate, equipment, or inventory. This collateral provides a source of repayment for bondholders if the issuer defaults.</li>
                <li>Guarantee: Guarantees from a parent company/another entity or a personal guaranetee can enhance the creditworthiness of the bond issue. These guarantees promise that the guarantor will fulfill the obligations of the issuer if they are unable to do so.</li>
                <li>Term sheet Covenants: Bond agreements may include financial and operational covenants that restrict the issuer's activities to ensure they maintain sufficient financial health to meet their bond obligations. These could include limitations on debt levels, dividend payments, or capital expenditures.</li>
                <li>Senior Repayments: All the bonds available on the platform are senior secured Bonds, which means that the repayment seniority in case of default would be the highest for these bond holders.</li>
            </ul>
        </div>
    },
]

export const BONDS_EVENTS = {
    "Bonds_Request_Callback": "Bonds_Request_Callback",
    "Bonds_Generate_Payment_Link": "Bonds_Generate_Payment_Link",
    "Bonds_Upload_CMR": "Bonds_Upload_CMR",
    "Bonds_select_client": "Bonds_select_client",
    "Bonds_Enter_Units": "Bonds_Enter_Units",
    "Clicked_Bonds_Detail_page": "Clicked_Bonds_Detail_page",
    "Clicked_Bonds_tab": "Clicked_Bonds_tab"
}


export const keyFeaturesPTC = {
  about:
    'The Receivable Securitisation Trust (ReST) offers Pass-Through Certificates (PTCs) that provide investors with an opportunity to invest in a diversified pool of trade receivables, offering investors access to predictable cash flows generated from these assets. This product is backed by AP Factors, a licensed Non-Banking Financial Company (NBFC), along with trade credit insurance, a fixed deposit for added security, and a first-loss default guarantee (FLDG), ensuring a robust and secure investment experience.',
  keyFeatures: [
    {
      key: 'Diversified Pool',
      value:
        'The PTCs are backed by a diversified pool of trade receivables, reducing risk through varied sources of income, the majority of the pool comprises companies with ratings of AAA, AA+, and AA.',
    },
    {
      key: 'Safeguarded',
      value: 'These PTC units will be held securely in your demat account.',
    },
    {
      key: 'Escrow Management',
      value:
        'The entire pool of invoices is managed through an escrow account overseen by an Axis trustee.',
    },
    {
      key: 'Credit Enhancement',
      value:
        'The investment is supported by a 10% First Loss Default Guarantee (FLDG) from AP Factors, adding a layer of security for investors.',
    },
    {
      key: 'Insurance Coverage',
      value:
        'A portion of the underlying receivables is backed by trade credit insurance, providing coverage of up to 25% to protect against defaults.',
    },
    {
      key: 'Easy Access',
      value:
        'Investors can conveniently purchase units of the PTCs through the tap Invest platform, with transparent information available for informed decision-making.',
    },
    {
      key: 'Regulatory Compliance',
      value:
        'The transaction adheres to Reserve Bank of India (RBI) guidelines, ensuring a robust legal and regulatory framework.',
    },
    {
      key: 'Professional Management',
      value:
        'Arth Padarth Factors & Finance Pvt Ltd is an RBI-registered NBFC, with a strong history of successful transactions, the leadership team brings extensive combined experience in the financial services sector.',
    },
  ],
  faqsPTC: [
    {
      question: 'What are Pass-Through Certificates (PTCs)?',
      answer:
        'PTCs are securitized debt instruments that give investors a claim on the cash flows generated from a pool of underlying assets, such as trade receivables.',
    },
    {
      question: 'How does the Special Purpose Vehicle (SPV) function in the PTC structure?',
      answer:
        'An SPV is created to isolate financial risk and manage the transaction related to the PTCs. It acquires underlying assets, such as invoices, and issues PTCs to investors',
    },
    {
      question: 'What role does the trustee play in the PTC transaction?',
      answer:
        "The trustee oversees the SPV's transactions, manages funds, and ensures compliance with relevant regulations while safeguarding the interests of investors.",
    },
    {
      question: 'How are returns distributed to PTC holders?',
      answer:
        'Returns from the pooled assets are distributed to investors based on the number of PTCs they hold, according to the terms specified in the PTC structure.',
    },
    {
      question: "Where do Investors hold these PTC's?",
      answer: 'Pass through certificates are held securely in your Demat account.',
    },
    {
      question: 'What are the key features of the securitization trust?',
      answer:
        'The trust acquires assets, manages cash flows, issues securities backed by those cash flows, and employs credit enhancements to improve investor security.',
    },
    {
      question: 'How does taxation work for PTC holders?',
      answer:
        "Income earned from PTCs is taxed under `Income from Other Sources` based on the individual's tax slab, with TDS of 25% applied to the interest component.",
    },
    {
      question: 'What is the significance of credit ratings for the underlying invoices?',
      answer:
        'Invoices are vetted and rated (e.g., AA+, AAA, A+, A, BBB+) to ensure credit quality, providing additional security for investors in the PTCs.',
    },
    {
      question: "How are investors' rights protected in this structure?",
      answer:
        'Investors have equal rights to cash flows from the underlying assets, and the structure includes various safeguards to enhance credit quality and mitigate risk.',
    },
    {
      question: 'When will the Pass-Through Certificates (PTCs) be allotted?',
      answer: 'The allotment date for the PTCs is set for November 13th.',
    },
    {
      question: 'What is securitisation?',
      answer:
        'Securitisation is the financial process of pooling various types of debt—including loans or receivables—and selling them as investment products. It involves creating a separate entity (Special Purpose Vehicle) that holds these assets and issues financial instruments to investors.',
    },
    {
      question: 'What legal opinions support this transaction?',
      answer:
        'The transaction is supported by legal opinions from Sanaatan Law Partners (Transaction Opinion) and Cyril Amarchand Mangaldas (Structure Note).',
    },
  ],
};