import { useQuery, PATH, Call } from '../../services/NetworkManager';

export function getBondDealDetails({ queryKey }) {
  const id = queryKey[0].split(',')[1];

  return Call({
    method: 'get',
    path: `${PATH.bondsDetail.replace('{{dealId}}', id)}`,
  });
}

export function getBondInvestmentTerms({ queryKey }) {
  const id = queryKey[0].split(',')[1];
  const quantity = queryKey[0].split(',')[2];
  const clientId = queryKey[0].split(',')[3];
  const customYield = queryKey[0].split(',')[4];

  let path = `${PATH.bondsInvestmentTerms.replace('{{dealId}}', id)}?qty=${quantity}`;

  if (clientId) {
    path = path + `&client-public-id=${clientId}`;
  }
  if (customYield) {
    path = path + `&yield=${customYield}`;
  } else {
    return null; // or handle the case when customYield is not available
  }
  return Call({
    method: 'get',
    path,
  });
}

export function getBondCashflow({ queryKey }) {
  const id = queryKey[0].split(',')[1];
  const quantity = queryKey[0].split(',')[2];

  return Call({
    method: 'get',
    path: `${PATH.bondsCashflow.replace('{{dealId}}', id)}?qty=${quantity}`,
  });
}

export function getCmrStatus({ queryKey }) {
  const id = queryKey[0].split(',')[1];

  return Call({
    method: 'get',
    path: `${PATH.cmrStatus.replace('{{clientId}}', id)}`,
  });
}

export const getPartnerConsiderationAmount = ({ queryKey }) => {
  const isin = queryKey[0].split(',')[1];
  const userYield = queryKey[0].split(',')[2];
  const qty = queryKey[0].split(',')[3];

  return Call({
    method: 'get',
    path: `${PATH.partnerConsiderationAmount}?isin=${isin}&yield=${userYield}&qty=${qty}`,
  });
};

export const postCmrFileUpload = (formData: FormData) => {
  return Call({
    method: 'post',
    path: PATH.cmrUpload,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const generatePaymentLink = ({
  unitConsiderationAmount,
  qty,
  dealId,
  dealName,
  maturityDate,
  amount,
  returnAmount,
  settlementDate,
  clientPublicId,
  partnerCommissionAmount,
  yieldOfInvestment,
  partnerYield,
}) => {
  return Call({
    method: 'post',
    path: PATH.bondsPaymentLink,
    data: {
      unitConsiderationAmount,
      qty,
      dealId,
      dealName,
      maturityDate,
      amount,
      returnAmount,
      settlementDate,
      clientPublicId,
      partnerCommissionAmount,
      yieldOfInvestment,
      partnerYield,
    },
  });
};

// Hooks
export function useBondDealDetails(dealId: string) {
  const { data, isLoading } = useQuery(`${['bondsDeal', dealId]}`, getBondDealDetails);
  return { data, isLoading };
}

export function useBondInvestmentTerms(
  dealId: string,
  quantity: number,
  clientId?: string,
  customYield?: string,
) {
  const { data, isLoading } = useQuery(
    `${['bondsInvestmentTerms', dealId, quantity, clientId, customYield]}`,
    getBondInvestmentTerms,
  );
  return { data, isLoading };
}

export function useBondCashflow(dealId: string, quantity: number) {
  const { data, isLoading } = useQuery(`${['bondsCashFlow', dealId, quantity]}`, getBondCashflow);
  return { data, isLoading };
}

export function useCmrStatus(clientId?: string) {
  const { data, isLoading, refetch } = useQuery(`${['bondsCmrStatus', clientId]}`, getCmrStatus, {
    enabled: !!clientId,
  });
  return { data, isLoading, refetch };
}

export function getUserPaymentBondsDetails({ key }) {
  return Call({
    method: 'get',
    path: PATH.createUserPaymentLinkBonds.replace('{{key}}', key),
  });
}

export function useUserPaymentBondsDetails(key?: string) {
  const { data, isLoading, error } = useQuery(
    ['userPaymentBondsDetails', key],
    () => getUserPaymentBondsDetails({ key }),
    {
      enabled: !!key,
    }
  );
  return { data, isLoading, error };
}

export function getCreateBondsPayment({
  key,
  phone,
  successPath,
  activePath,
  failedPath,
}: {
  key: string;
  phone: string;
  successPath: string;
  activePath: string;
  failedPath: string;
}) {
  return Call({
    method: 'post',
    path: PATH.CreateBondsUserPayments,
    data: {
      key,
      phone,
      successPath,
      activePath,
      failedPath,
    },
  });
}

export function usePartnerConsiderationAmount(isin: string, userYield: string, qty: number) {
  const { data, isLoading } = useQuery(
    `${['bondsPartnerConsiderationAmount', isin, userYield, qty]}`,
    getPartnerConsiderationAmount,
    {
      enabled: !!isin && !!userYield,
    },
  );

  return { data, isLoading };
}
