import React from 'react';
import TapLogo from '../../pages/Onboarding/TapLogo';
import HomeBackground from '../../assets/home-background.png';
import Lottie from 'react-lottie';
import loading from '../../assets/lotties/loading.json';
import { useProfile } from '../../context/ProfileContext';

const GlobalLoadingPage = () => {
  const { profileData } = useProfile();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <>
      <div className="max-w-screen-2xl mx-auto px-4 md:px-8">
        {/* Hero */}
        <div className="max-sm:mb-24 lg:min-h-screen relative">
          <img
            src={HomeBackground}
            alt="Partner App Home"
            className="absolute hidden lg:block top-12 max-h-screen w-full"
          />
          <div className="flex flex-col items-center justify-center lg:pt-12 lg:pl-12 lg:pr-24 lg:pb-12 lg:space-y-4 md:mt-5">
            {profileData?.brandLogoBlack && profileData?.brandLogoBlack !== '' ? (
              <img src={profileData.brandLogoBlack} alt="brand-logo" className="h-8 w-auto" />
            ) : (
              <TapLogo />
            )}
          </div>
          <div className="py-12 sm:py-36 lg:py-40 xl:py-60 z-10 relative">
            <h3 className="text-center text-xl md:text-2xl xl:text-[40px] font-medium leading-10 md:mb-2 lg:mb-6 tracking-tighter">
              <Lottie options={defaultOptions} height={40} width={40} />

              <span className="text-green-700 sm:leading-10 block font-semibold text-2xl md:text-3xl xl:text-5xl lg:mt-4">
                Loading Partner Portal
              </span>
            </h3>
            <p className="text-center text-black/50 text-sm sm:text-base md:text-lg lg:text-2xl">
              An exclusive platform for Tap Invest Partners
            </p>
            {/* <Link to={isUserAuth ? '/deals' : '/login'}>
              <button
                type="button"
                className="bg-green-600 mx-auto mt-8 flex items-center gap-2 border border-green-700 text-white text-sm font-semibold h-10 px-7 rounded-xl"
              >
                {isUserAuth ? 'Open your dashboard' : 'Get Started'} <LaunchIcon fontSize="small" />{' '}
              </button>
            </Link> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default GlobalLoadingPage;
