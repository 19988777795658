import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import RemoteConfigContextProvider from './context/RemoteConfigContext';
import posthog from 'posthog-js';
import mixpanel from 'mixpanel-browser';
import { PostHogProvider } from 'posthog-js/react';
import { ProfileProvider } from './context/ProfileContext';

mixpanel.init(process.env.REACT_APP_MIXPANEL_ID ?? '59f34224cc8e50fdb4247966d3266e30', {
  debug: process.env.REACT_APP_ENV !== 'production',
  track_pageview: 'url-with-path',
  persistence: 'localStorage',
});
if (process.env.REACT_APP_POSTHOG_KEY && process.env.REACT_APP_ENV == 'production') {
  posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
    api_host: 'https://us.i.posthog.com',
  });
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <ProfileProvider>
        <RemoteConfigContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </RemoteConfigContextProvider>
      </ProfileProvider>
    </PostHogProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
