import * as R from 'ramda';
import { formatDate } from '../../utils/date';
import { getRupee, twoDecimalNumber } from '../../utils/numbers';
import { KYC_STATUS } from './constants';
import { getInvestmentStatusTag } from '../utils/investment';
import { ChevronRight } from '@mui/icons-material';
import DealNameAndStatus from '../../components/organisms/Tables/CellRenderer/DealNameAndStatus';

const getKycStatusTag = (kycStatus) => {
  if (kycStatus == KYC_STATUS.REPAID_ON_TIME) {
    return (
      <div className="bg-green-200 w-fit rounded-lg text-green-700 py-1 px-2 text-xs font-semibold">
        Completed
      </div>
    );
  }
  if (kycStatus == KYC_STATUS.SCHEDULED) {
    return (
      <div className="bg-gray-200 w-fit rounded-lg text-gray-700 py-1 px-2 text-xs font-semibold">
        Ongoing
      </div>
    );
  }
};

function createData(data) {
  const {
    companyName,
    state,
    status,
    investedOn,
    amount,
    maturityDate,
    payoutDate,
    irr,
    payoutAmount,
    isReinvestInvestment,
  } = data;

  return {
    dealName: (
      <DealNameAndStatus
        name={companyName}
        status={status}
        isReinvestInvestment={isReinvestInvestment}
        dealData={data}
      />
    ),
    investedOn: investedOn ? formatDate(investedOn) : '-',
    amount: (
      <div className="flex items-center gap-1">
        {getRupee.format(amount)} {getInvestmentStatusTag(state)}
      </div>
    ),
    maturityDate: maturityDate ? formatDate(maturityDate) : '-',
    irr: `${irr ? twoDecimalNumber(irr) + '%' : '-'}`,
    payoutDate,
    payoutAmount: payoutAmount ? getRupee.format(payoutAmount) : '-',
  };
}

export const getRowsAndCols = (data) => {
  const rows = [];
  const data_row = R.path(['items'], data);

  data_row?.forEach((element) => {
    rows.push(createData(element));
  });

  return rows;
};
