import React from 'react';
import { KYC_STATUS } from '../../../../pages/ClientDetail/constants';
import { ChevronRight } from '@mui/icons-material';
import { useLayoutStore } from '../../Layout/store';

const DealNameAndStatus = ({ name = '', status = '', isReinvestInvestment = false, dealData }) => {
  const { setIsReinvestModalOpen, setReinvestDeal } = useLayoutStore(
    ({ setIsReinvestModalOpen, setReinvestDeal }) => ({ setIsReinvestModalOpen, setReinvestDeal }),
  );

  const getStatusTag = (status) => {
    if (status === 'SUCCESS') {
      return (
        <div className="bg-green-700/15 w-fit rounded-xl text-green-700 py-1 px-2 text-xs font-semibold">
          Success
        </div>
      );
    }
    if (status === 'INITIATED') {
      return (
        <div className="bg-blue-700/15 w-fit rounded-xl text-blue-700 py-1 px-2 text-xs font-semibold">
          Initiated
        </div>
      );
    }
    if (status === 'PENDING') {
      return (
        <div className="bg-yellow-700/15 w-fit rounded-xl text-yellow-700 py-1 px-2 text-xs font-semibold">
          Pending
        </div>
      );
    }
  };

  const handleReinvest = (e) => {
    e.stopPropagation();
    setReinvestDeal(dealData);
    setIsReinvestModalOpen(true);
  };

  return (
    <>
      <div className="flex flex-col gap-1">
        {name}
        <div className="flex items-center gap-2">
          {getStatusTag(status)}
          {isReinvestInvestment && (
            <button
              onClick={handleReinvest}
              className="bg-green-600 px-2 py-0.5 rounded-full text-white text-xs font-medium flex items-center gap-0.5"
            >
              Reinvest <ChevronRight fontSize="small" />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default DealNameAndStatus;
