import React from 'react';
import Tables from '../../components/organisms/Tables/Tables';
import Box from '@mui/material/Box';
import { getBondsRowsAndCols, getGoldRowsAndCols, getIDRowsAndCols, getRowsAndCols } from './utils';
import { SORTABLE_COLUMNS } from '../../pages/Investments/constants';
import { useProfile } from '../../context/ProfileContext';

const columnsID = [
  { id: 'dealName', label: 'Deal Name', minWidth: 170, align: 'left' },
  {
    id: 'clientName',
    label: 'Client Name',
    minWidth: 170,
    align: 'left',
  },
  {
    id: 'investedDate',
    label: 'Invested on',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'investedAmount',
    label: 'Invested Amount',
    minWidth: 100,
    align: 'left',
  },
  { id: 'payoutOn', label: 'Payout On', minWidth: 100, align: 'left' },
  {
    id: 'payoutAmount',
    label: 'Payout Amount',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'irr',
    label: 'IRR',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'earnedIncome',
    label: 'Your Earning',
    minWidth: 100,
    align: 'left',
  },
];
const columnsBonds = [
  { id: 'dealName', label: 'Deal Name', minWidth: 170, align: 'left' },
  {
    id: 'clientName',
    label: 'Client Name',
    minWidth: 170,
    align: 'left',
  },
  {
    id: 'investedAmount',
    label: 'Invested Amount',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'ytm',
    label: 'Yield to Maturity',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'settlementOn',
    label: 'Settlement on Date',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'unit',
    label: 'Units purchased',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'maturityOn',
    label: 'Maturity Date',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'earnedIncome',
    label: 'Your Earning',
    minWidth: 100,
    align: 'left',
  },
];
const columnsGold = [
  { id: 'dealName', label: 'Deal Name', minWidth: 170, align: 'left' },
  {
    id: 'clientName',
    label: 'Client Name',
    minWidth: 170,
    align: 'left',
  },
  {
    id: 'investedDate',
    label: 'Invested on',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'investedAmount',
    label: 'Invested Amount',
    minWidth: 100,
    align: 'left',
  },
  { id: 'payoutOn', label: 'Payout On', minWidth: 100, align: 'left' },
  {
    id: 'payoutAmount',
    label: 'Payout Amount',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'irr',
    label: 'IRR',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'earnedIncome',
    label: 'Your Earning',
    minWidth: 100,
    align: 'left',
  },
];
const columns = [
  { id: 'dealName', label: 'Deal Name', minWidth: 170, align: 'left' },
  {
    id: 'clientName',
    label: 'Client Name',
    minWidth: 170,
    align: 'left',
  },
  {
    id: 'investedDate',
    label: 'Invested on',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'investedAmount',
    label: 'Invested Amount',
    minWidth: 100,
    align: 'left',
  },
  { id: 'payoutOn', label: 'Payout On', minWidth: 100, align: 'left' },
  {
    id: 'payoutAmount',
    label: 'Payout Amount',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'irr',
    label: 'IRR',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'earnedIncome',
    label: 'Your Earning',
    minWidth: 100,
    align: 'left',
  },
];

const getFilteredColumns = (columns, showEarnedIncome) => {
  // Filter out the earnedIncome column if showEarnedIncome is false
  return columns.filter((column) => (column.id === 'earnedIncome' ? showEarnedIncome : true));
};

const InvestmentTable = ({
  data,
  pageSize,
  pageNo,
  orderBy,
  order,
  handleChangePage,
  handleChangeRowsPerPage,
  handleChangeSort,
  tab,
}) => {
  const { profileData } = useProfile();

  const showEarnedIncome = profileData?.clientActions?.showEarnedIncome;

  const filteredColumnsID = getFilteredColumns(columnsID, showEarnedIncome);
  const filteredColumnsBonds = getFilteredColumns(columnsBonds, showEarnedIncome);
  const filteredColumnsGold = getFilteredColumns(columnsGold, showEarnedIncome);
  const filteredColumns = getFilteredColumns(columns, showEarnedIncome);

  const row =
    tab === 'INVOICE_DISCOUNTING'
      ? getIDRowsAndCols(data)
      : tab === 'BOND'
      ? getBondsRowsAndCols(data)
      : tab === 'DIGITAL_GOLD'
      ? getGoldRowsAndCols(data)
      : getRowsAndCols(data);

  const totalData = data?.data?.totalElements;

  return (
    <Box>
      <Tables
        totalData={totalData}
        columns={
          tab === 'INVOICE_DISCOUNTING'
            ? filteredColumnsID
            : tab === 'BOND'
            ? filteredColumnsBonds
            : tab === 'DIGITAL_GOLD'
            ? filteredColumnsGold
            : filteredColumns
        }
        sortableColumns={SORTABLE_COLUMNS}
        rows={row}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangeSort={handleChangeSort}
        rowsPerPage={pageSize}
        page={pageNo}
        orderBy={orderBy}
        order={order}
      />
    </Box>
  );
};

export default InvestmentTable;
