import React, { useState } from 'react';
import LoadingDots from '../../components/molecules/LoadingDotsProps';


interface ProductDeckProps {}

const ProductDeck: React.FC<ProductDeckProps> = () => {
  const [loading, setLoading] = useState(true);

  return (
    <div className="flex flex-col max-w-7xl mx-auto">
      <h1 className="font-medium text-4xl mb-9 mt-6">Partner’s Starter Pack</h1>
      {loading && (
        <div className="flex justify-center items-center h-[calc(40vh-75px)]">
          Loading
          <LoadingDots />
        </div>
      )}
      <iframe
        src="https://drive.google.com/embeddedfolderview?id=12b1LjdY4fv83JUzUt8kKf8YQo9t75jpL#list"
        className={`w-full h-[calc(100vh-75px)] ${loading ? 'hidden' : ''}`}
        title="Partner's Starter Pack"
        onLoad={() => setLoading(false)}
      />
    </div>
  );
};

ProductDeck.displayName = 'ProductDeck';

export default ProductDeck;
