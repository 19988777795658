import React from 'react';

interface AboutBondsProps {
  data: {
    about: string | null;
    amount: number | null;
    email: string | null;
    expiryDateTime: number | null;
    interest: string | null;
    isin: string | null;
    key: string | number | null;
    logo: string | null;
    maturityDate: string | null;
    miniSummary: string | null;
    name: string | null;
    partnerName: string | null;
    phone: string | null;
    principal: string | null;
    qty: number | null;
    returnAmount: number | null;
    settlementDate: string | null;
    unitConsiderationAmount: number | null;
    dealName: string | null;
    yield: number | null;
    tags: string | null;
    investmentResponseDTO: {
      financeType: string | null;
      investmentId: number | null;
      ipAddress: string | number | null;
      meta: {
        size: string | number | null;
        pageNumber: string | number | null;
        totalPages: string | number | null;
        totalElements: string | number | null;
      };

      payment: {
        paymentOperator: string | null;
        paymentOrderId: number | null;
        paymentSessionId: string | null;
      };
      paymentMode: string | null;
    };
  };
}

const AboutBonds: React.FC<AboutBondsProps> = ({ data }) => {
  return (
    <div className="px-5 mt-10 rounded-md max-w-lg flex flex-col gap-6">
      <img src={data?.logo} alt="Logo" className="w-16 h-16 mr-3" />
      <div className="flex flex-col">
        <div className="flex items-center gap-4">
          <h1 className="text-xl font-semibold text-slate-800">{data?.dealName}</h1>
          {data?.tags && (
            <span className="text-xs text-green-700 bg-[#1665341F] font-medium px-[6px] py-[5px] rounded-md">
              {data?.tags}
            </span>
          )}
        </div>
        <p className="text-sm text-gray-500">ISIN: {data?.isin}</p>
        <p className="text-sm text-gray-500 mt-4">{data?.miniSummary}</p>
      </div>
      <div className="flex mt-auto w-full min-h-0 flex-col bg-black/[.02] rounded-lg border border-stone-300">
        <div className="grid grid-cols-2 md:col-span-1">
          <div className="p-4 border-r border-r-stone-300 pb-3">
            <h3 className="text-stone-700 font-semibold text-xs uppercase mb-2">Interest</h3>
            <div className="h-8 mix-blend-darken max-w-[300px] text-xl font-medium text-stone-700">
              {data?.interest}
            </div>
          </div>
          <div className="p-4">
            <h3 className="text-stone-700 font-semibold text-xs uppercase mb-2">Principal</h3>
            <div className="h-8 mix-blend-darken max-w-[300px] text-xl font-medium text-stone-700">
              {data?.principal}
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <h2 className="text-lg font-semibold text-slate-800">About {data?.dealName}</h2>
        <p className="text-sm text-gray-500 mt-3">{data?.about}</p>
      </div>
      <div>
        <h3 className="text-stone-700 text-lg font-medium mb-3">Settlement Time & Cycles</h3>
        <div className="border border-stone-200 rounded-lg bg-white/80 py-5 px-4 md:px-6 max-w-screen-sm">
          <div className="grid grid-cols-2 border-b py-1.5 border-b-stone-200">
            <h4 className="text-xs text-stone-600">Time</h4>
            <h4 className="text-xs text-stone-600">Settlement</h4>
          </div>

          <div className="grid grid-cols-2 py-2 border-b border-b-black/10 border-dashed">
            <p className="text-stone-950/70 text-xs">12 AM - 2PM</p>
            <p className="text-stone-950/70 text-xs">Same day settlement</p>
          </div>

          <div className="grid grid-cols-2 py-2 border-b border-b-black/10 border-dashed">
            <p className="text-stone-950/70 text-xs">Post 2PM</p>
            <p className="text-stone-950/70 text-xs">Next working day</p>
          </div>

          <div className="grid grid-cols-2 py-2">
            <p className="text-stone-950/70 text-xs">Holidays & Weekends</p>
            <p className="text-stone-950/70 text-xs">Next working day</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutBonds;
