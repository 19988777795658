import React, { useState, useEffect } from 'react';
import { useMobileSize } from '../../../../hooks/useScreenSize';

const MessageCycler = ({ messages }) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true); // Visibility for fade effect

  useEffect(() => {
    const interval = setInterval(() => {
      // Start fade-out
      setIsVisible(false);

      // Wait for the fade-out to complete, then switch the message
      setTimeout(() => {
        setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
        setIsVisible(true); // Fade back in
      }, 500); // Time for fade-out transition
    }, 5000); // Change message every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  // Handler for clicking on the dots
  const handleDotClick = (index) => {
    // Trigger fade-out effect
    setIsVisible(false);

    // After fade-out, switch the message and trigger fade-in
    setTimeout(() => {
      setCurrentMessageIndex(index);
      setIsVisible(true);
    }, 500);
  };
  const isMobile = useMobileSize();
  return (
    <div className="flex flex-col justify-center">
      {/* Message Box with transition */}
      <div
        className={`px-4 py-3 bg-amber-50 mt-8 border border-amber-200 rounded-xl flex items-center gap-2 transition-opacity duration-500 ${
          isVisible ? 'opacity-100' : 'opacity-0'
        }`}
        style={{ minHeight: '80px', width: isMobile ? 'inherit' : '450px' }} // Set a consistent height
      >
        {messages[currentMessageIndex].icon}
        <p className="text-amber-700 text-xs font-medium">{messages[currentMessageIndex].text}</p>
      </div>

      {/* Dots for navigation */}
      <div className="flex gap-2 mt-2 ml-3">
        {messages.map((_, index) => (
          <span
            key={index}
            onClick={() => handleDotClick(index)} // Handle dot click
            className={`h-2 w-2 rounded-full cursor-pointer ${
              currentMessageIndex === index ? 'bg-amber-600' : 'bg-amber-600/15'
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default MessageCycler;
