import React, { useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

const ProtectedRoutes = ({ children }) => {
  const [isUserAuth, setUserAuth] = useState(localStorage.getItem('token'));

  if (!isUserAuth) {
    return <Navigate to="/login" />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoutes;
