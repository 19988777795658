import React, { useCallback, useEffect, useState } from 'react';
import PersonalDetails from './components/PersonalDetails';
import InvestmentDetails from './components/InvestmentDetails';
import AboutTapReserve from './components/AboutTapReserve';
import {
  postCreateP2PPayment,
  postCreateWalletPayment,
  postCreateMetalPayment,
  getUserPaymentDetails,
  getUserPaymentWalletDetails,
  getCreateMetalLink,
} from './useGetUserPayment';
import LenderOnboardingAgreement from './components/LenderOnboardingAgreement';
import { useLocation, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import SuccessCardComponent from './components/SuccessCardComponent';
import ErrorCardComponent from './components/ErrorCardComponent';
import { Box, Button, Dialog, IconButton, TextField } from '@mui/material';
import { CloseOutlinedIcon } from '../../assets/mui';
import WalletDetails from './components/WalletDetails';
import { useMobileSize } from '../../hooks/useScreenSize';
import AboutWallet from './components/AboutWallet';
import { ReactComponent as SearchFile } from '../../assets/svg/searchFile.svg';
import { ClipLoader } from 'react-spinners';
import BondsDetails from './components/BondsDetails';
import AboutBonds from './components/AboutBonds';
import {
  getCreateBondsPayment,
  getUserPaymentBondsDetails,
} from '../BondsDealDetail/useBondDealDetails';
import AboutGold from './components/AboutGold';
import GoldDetails from './components/GoldDetails';
import { fourDecimalNumberWithoutRounding } from '../../utils/numbers';

interface CashfreeWindow extends Window {
  Cashfree: any;
}

const UserPayment: React.FC = ({}) => {
  const { search } = useLocation();

  const key = new URLSearchParams(search).get('key');
  const paymentStatus = new URLSearchParams(search).get('paymentStatus');
  const rechargeStatus = new URLSearchParams(search).get('rechargeStatus');
  const { productType } = useParams<{ productType: string }>();

  const [showLenderOnboardingAgreement, setShowLenderOnboardingAgreement] = React.useState(false);
  const [phone, setPhone] = React.useState('');
  const [openPhoneModal, setOpenPhoneModal] = React.useState(false);
  const isMobile = useMobileSize();
  const [loading, setLoading] = React.useState(false);

  const [data, setData] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [paymentDetailsError, setPaymentDetailsError] = useState(null);
  const [priceExpiresAt, setPriceExpiresAt] = useState(null);

  const fetchData = useCallback(async () => {
    setDataLoading(true);

    try {
      let response;
      if (productType === 'p2p') {
        response = await getUserPaymentDetails({ key });
      } else if (productType === 'bond') {
        response = await getUserPaymentBondsDetails({ key });
      } else if (productType === 'wallet') {
        response = await getUserPaymentWalletDetails({ key });
      } else if (productType === 'metal') {
        response = await getCreateMetalLink({ key });
      }

      if (response) {
        setData(response);

        // Set the priceExpiresAt state
        const expiresAt = new Date(response?.result?.priceExpiresAt).getTime();
        setPriceExpiresAt(expiresAt);
      }
    } catch (error) {
      setPaymentDetailsError(error);
    } finally {
      setDataLoading(false);
    }
  }, [productType, key]);

  // Fetch data on initial render
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Set up the timer to refetch data when priceExpiresAt is reached
  useEffect(() => {
    if (priceExpiresAt) {
      const currentTime = new Date().getTime();
      const timeLeft = priceExpiresAt - currentTime;

      if (timeLeft > 0) {
        const timer = setTimeout(() => {
          fetchData();
        }, timeLeft);

        return () => clearTimeout(timer);
      } else {
        // If the price has already expired, fetch data immediately
        fetchData();
      }
    }
  }, [fetchData, priceExpiresAt]);

  const closeModal = () => {
    setShowLenderOnboardingAgreement(false);
  };

  const handleInvestment = (key: string, paymentSessionId: string = '') => {
    setLoading(true);

    if (productType === 'p2p') {
      postCreateP2PPayment({ key, phone: `+91${phone}` })
        .then((res) => {
          const paymentSessionId = res.result.payment.paymentSessionId;
          const cf = new (window as unknown as CashfreeWindow).Cashfree(paymentSessionId);
          cf.redirect();
        })
        .catch((err) => {
          console.error(err);
          toast.error(
            `${err?.response?.data?.message || 'something went wrong! Please try again'}`,
            {
              duration: 2500,
              position: 'top-center',
            },
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (productType === 'bond') {
      getCreateBondsPayment({
        key,
        phone: `+91${phone}`,
        successPath: window.location.href.split('?')[0],
        activePath: window.location.href.split('?')[0],
        failedPath: window.location.href.split('?')[0],
      })
        .then((res) => {
          const cf = new (window as unknown as CashfreeWindow).Cashfree(paymentSessionId);
          cf.redirect();
        })
        .catch((err) => {
          toast.error(
            `${err?.response?.data?.message || 'something went wrong! Please try again'}`,
            {
              duration: 2500,
              position: 'top-center',
            },
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (productType === 'metal') {
      postCreateMetalPayment({
        key,
        phone: `+91${phone}`,
        successPath: window.location.href.split('?')[0],
        activePath: window.location.href.split('?')[0],
        failedPath: window.location.href.split('?')[0],
        blockId: data?.result.blockId,
        price: data?.result.price,
        quantity: fourDecimalNumberWithoutRounding(
          Number(data?.result.amountPayable) / (1.03 * Number(data?.result.price)),
        ),
      })
        .then((res) => {
          const paymentSessionId = res.result.payment.paymentSessionId;
          const cf = new (window as unknown as CashfreeWindow).Cashfree(paymentSessionId);
          cf.redirect();
        })
        .catch((err) => {
          toast.error(err.response.data.message || 'something went wrong', {
            duration: 2500,
            position: 'top-center',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      postCreateWalletPayment({ key, phone: `+91${phone}` })
        .then((res) => {
          const paymentSessionId = res.result.paymentSessionId;
          const cf = new (window as unknown as CashfreeWindow).Cashfree(paymentSessionId);
          cf.redirect();
        })
        .catch((err) => {
          toast.error('Something went wrong', {
            duration: 2500,
            position: 'top-center',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      {key ? (
        paymentDetailsError ? (
          <div className="flex flex-col gap-2 justify-center items-center mt-[12%]">
            <SearchFile />
            <h1>This link has expired!</h1>
            <h4>
              Please request for another payment link from your investor to recharge your Tap
              Wallet!
            </h4>
          </div>
        ) : dataLoading ? (
          <Box
            // sx={{ background: "#FBFBF6" }}
            height={'100vh'}
            width={'100vw'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <ClipLoader loading={dataLoading} size={150} />
          </Box>
        ) : (
          <>
            <div className="min-h-screen  flex justify-center pt-8 pb-6 px-28 ">
              <div
                className={`flex justify-between gap-10 ${
                  isMobile ? 'flex-col-reverse' : 'flex-row'
                }`}
              >
                <div className={`py-6 gap-10 mb-10`}>
                  <PersonalDetails data={data?.result} loading={dataLoading} />
                  {productType === 'p2p' && <AboutTapReserve />}
                  {productType === 'wallet' && <AboutWallet />}
                  {productType === 'bond' && <AboutBonds data={data?.result} />}
                  {productType === 'metal' && <AboutGold data={data?.result} />}
                </div>
                {productType === 'p2p' && (
                  <InvestmentDetails
                    data={data?.result}
                    loading={dataLoading}
                    setShowLenderOnboardingAgreement={setShowLenderOnboardingAgreement}
                    makeInvestment={() => {
                      setOpenPhoneModal(true);
                    }}
                    setPhone={setPhone}
                  />
                )}
                {productType === 'wallet' && (
                  <WalletDetails
                    data={data?.result}
                    loading={dataLoading}
                    setShowLenderOnboardingAgreement={setShowLenderOnboardingAgreement}
                    makeInvestment={() => {
                      setOpenPhoneModal(true);
                    }}
                    setPhone={setPhone}
                  />
                )}
                {productType === 'bond' && (
                  <BondsDetails
                    data={data?.result}
                    loading={dataLoading}
                    setShowLenderOnboardingAgreement={setShowLenderOnboardingAgreement}
                    makeInvestment={() => {
                      setOpenPhoneModal(true);
                    }}
                    setPhone={setPhone}
                  />
                )}
                {productType === 'metal' && (
                  <GoldDetails
                    data={data?.result}
                    loading={dataLoading}
                    setShowLenderOnboardingAgreement={setShowLenderOnboardingAgreement}
                    makeInvestment={() => {
                      setOpenPhoneModal(true);
                    }}
                  />
                )}
              </div>
            </div>
            <LenderOnboardingAgreement
              closeModal={closeModal}
              lendboxAgreementUrl={`https://cdn.tapinvest.in/documents/P2PLenderAgreement.pdf`}
              ShowLenderOnboardingAgreement={showLenderOnboardingAgreement}
            />
            {/* phone modal */}
            <Dialog
              maxWidth="sm"
              fullWidth
              open={openPhoneModal}
              onClose={() => {
                setOpenPhoneModal(false);
                setPhone('');
              }}
            >
              <div className="shadow-lg rounded-2xl bg-[#FBFBF6] ">
                <div className="flex justify-between items-center p-4 pb-8 rounded-t-lg bg-[#FBFBF6] border border-t-0 border-x-0 ">
                  <div>
                    <h6 className="mb-1 text-lg font-semibold">Enter your phone number</h6>
                    <p className="text-sm text-gray-600">
                      Verify your phone number for verification
                    </p>
                  </div>
                  <IconButton
                    onClick={() => {
                      setOpenPhoneModal(false);
                      setPhone('');
                    }}
                  >
                    <CloseOutlinedIcon />
                  </IconButton>
                </div>
                <div className="p-4 ">
                  <h4 className="text-sm text-gray-700 font-medium mb-2">
                    Enter your Phone Number
                  </h4>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="12348908XX"
                    value={phone}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value) && value.length <= 10) {
                        setPhone(value);
                      } else {
                        toast.error('Invalid Phone Number', {
                          duration: 2500,
                          position: 'top-center',
                        });
                        return;
                      }
                    }}
                    InputProps={{
                      style: {
                        backgroundColor: 'white',
                        width: '300px',
                        height: '50px',
                        borderRadius: '8px',
                      },
                    }}
                  />
                </div>
                <div className="flex justify-end p-4 bg-[#FBFBF6] rounded-lg">
                  <Button
                    onClick={() =>
                      handleInvestment(
                        data?.result?.key || key,
                        data?.result?.investmentResponseDTO?.payment?.paymentSessionId,
                      )
                    }
                    disabled={!phone}
                    variant="contained"
                    sx={{
                      borderRadius: '12px',
                      border: !phone ? '' : '1px solid #15803D',
                      background: !phone
                        ? '#D3D3D3'
                        : 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.12) 119.05%), #16A34A',
                      boxShadow: !phone ? 'none' : '0px 1.1px 1.8px -0.3px rgba(0, 0, 0, 0.10)',
                      color: 'white',
                      padding: '8px 16px',
                      transition: 'background 0.3s ease-in-out, transform 0.3s ease-in-out',
                      cursor: !phone ? 'not-allowed' : 'pointer',
                      '&:hover': {
                        background: !phone
                          ? '#D3D3D3'
                          : 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.12) 119.05%), #15803D',
                      },
                    }}
                    onMouseDown={(e) => {
                      if (phone) e.currentTarget.style.background = '#15803D';
                    }}
                    onMouseUp={(e) => {
                      if (phone)
                        e.currentTarget.style.background =
                          'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.12) 119.05%), #16A34A';
                    }}
                  >
                    {loading ? 'Processing...' : 'Proceed'}
                  </Button>
                </div>
              </div>
            </Dialog>
          </>
        )
      ) : paymentStatus === 'PAID' || rechargeStatus === 'PAID' ? (
        <SuccessCardComponent
          title={
            productType === 'p2p' || productType === 'bond' || productType === 'metal'
              ? 'Payment Successful'
              : 'Wallet Recharge Successful'
          }
        />
      ) : (
        <ErrorCardComponent
          title={
            productType === 'p2p' || productType === 'bond' || productType === 'metal'
              ? 'Payment Failed'
              : 'Oops! Wallet Recharge Failed'
          }
        />
      )}
    </>
  );
};

export default UserPayment;
