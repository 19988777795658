import { useState, useEffect } from 'react';

export function useTimer(defaultValue = 30, options) {
  const [timer, setTimer] = useState(defaultValue);
  const [isStopped, setIsStopped] = useState(0);

  useEffect(() => {
    let timerRef = null;
    if (isStopped === 1) {
      timerRef = setInterval(() => {
        setTimer((val) => {
          if (val - 1 <= 0) {
            clearInterval(timerRef);
          }
          return val - 1;
        });
      }, 1000);
    }
    return () => {
      clearInterval(timerRef);
    };
  }, [isStopped]);

  useEffect(() => {
    if (timer <= 0) {
      if (!options?.noResetAtEnd) {
        reset();
      }
      typeof options?.onEnd === 'function' && options.onEnd();
    }
  }, [timer]);
  const reset = () => {
    setTimer(defaultValue);
    setIsStopped(0);
  };

  const start = () => {
    setIsStopped(1);
  };
  return { timer, reset, start };
}
