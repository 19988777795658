import React, { createContext, useContext, useState } from 'react';

const ProfileContext = createContext();

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
};

export const ProfileProvider = ({ children }) => {
  const [profileData, setProfileData] = useState({
    configEnabled: true,
    type: 'DEFAULT',
    brandLogoBlack: '',
    brandLogoWhite: '',
    publicId: 'CONFIG-BAF03A2',
    name: '',
    features: {},
    productTypes: {},
    clientActions: {},
    internalActions: {},
  });

  const updateProfileData = (data) => {
    setProfileData((prevProfileData) => {
      const updatedProfileData = Object.keys(prevProfileData).reduce((acc, key) => {
        if (typeof prevProfileData[key] === 'object' && prevProfileData[key] !== null) {
          // For nested objects, we match keys and update values
          acc[key] = { ...prevProfileData[key], ...data[key] };
        } else {
          // For primitive values, update if the key exists in 'data'
          acc[key] = data.hasOwnProperty(key) ? data[key] : prevProfileData[key];
        }
        return acc;
      }, {});

      return updatedProfileData;
    });
  };

  const resetProfileData = () => {
    setProfileData({
      configEnabled: true,
      type: 'DEFAULT',
      brandLogoBlack: '',
      brandLogoWhite: '',
      publicId: 'CONFIG-BAF03A2',
      features: {},
      productTypes: {},
      clientActions: {},
      internalActions: {},
    });
  };

  return (
    <ProfileContext.Provider value={{ profileData, updateProfileData, resetProfileData }}>
      {children}
    </ProfileContext.Provider>
  );
};
